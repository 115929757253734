import baseUrl from "../config/baseUrl";

const assignUserInsideScan = async ({
  token,
  assigned_user,
  case_number,
  email,
  category_id,
  categoryName,
  rowCategory,
  folderName,
  document_id,
  scan_list_module,
}) => {
  const formData = new FormData();
  formData.append("assigned_user", assigned_user);
  formData.append("email", email);
  formData.append("category_id", `'${category_id}'`);
  formData.append("uncategorized_from_name", folderName);
  formData.append("document_id", document_id);
  formData.append("scan_list_module", scan_list_module);

  try {
    const response = await fetch(
      `${baseUrl}/caselist/bulk_assigned_user_scanner_inside_case_list`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default assignUserInsideScan;
