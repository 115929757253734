import React, { useState, useContext } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { RiCloseCircleLine } from "react-icons/ri";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ButtonUI from "../material-ui/ButtonUI";
import changeUserStatus from "../../api/changeUserStatus";
import { AuthContext } from "../../context/Auth/AuthContext";

const StatusPopup = ({ setUserAddedToTrue, tableValues }) => {
  const [open, setOpen] = useState(false);
  const { token, userID } = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDeleteDocumentFormSubmit = async () => {
    const deleteDocumentFormResponse = await changeUserStatus({
      token: token,
      user_id: userID,
      id: tableValues.id,
      status: tableValues.status === "Active" ? "In Active" : "Active",
    });

    if (deleteDocumentFormResponse?.code === 200) {
      setUserAddedToTrue();
      setOpen(false);
      toast.success("User Status is successfully changed", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={
          tableValues.status === "In Active" ? (
            <HiOutlineCheckCircle />
          ) : (
            <RiCloseCircleLine />
          )
        }
        style={{
          backgroundColor:
            tableValues.status === "In Active" ? "#28a745" : "#ffc107",
          color: "white",
          marginRight: "5px",
        }}
        onClickFunc={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="edit-doc-modal">
          <div className="popup-header-svg-icon">
            {tableValues.status === "In Active" ? (
              <HiOutlineCheckCircle />
            ) : (
              <RiCloseCircleLine />
            )}
          </div>
          <div className="popup-header-title">Change User Status</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to user status ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="No"
            style={{
              margin: "15px 0px",
              backgroundColor: "#fff",
              color: "#007bff",
              border: "1px solid #007bff",
            }}
            onClickFunc={handleClose}
          />
          <ButtonUI
            formButton={false}
            variant="outlined"
            buttonText="Yes"
            style={{
              margin: "15px",
              backgroundColor: "#fff",
              color: "##007bff",
              border: "1px solid #007bff",
            }}
            autoFocus={true}
            onClickFunc={() => {
              onDeleteDocumentFormSubmit();
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusPopup;
