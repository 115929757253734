import React, { useState, useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TbChecklist } from "react-icons/tb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import assignUser from "../../../api/assignUser";
import "../../../styles/AssignUserPopup.css";
import assignUserInsideScan from "../../../api/assignUserInsideScan";

const rejectCaseFormSchema = yup.object().shape({
	user: yup.string().required("User is required field"),
});

const AssignUserPopup = ({
	userList,
	isEntireTableRowsSelected,
	changeDropdownVisibilityFunc,
	setDocumentUpdatedToTrue,
	docId,
	email,
	category_id,
	categoryName,
	rowCategory,
	folderName,
}) => {
	const [open, setOpen] = useState(false);
	const { token } = useContext(AuthContext);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(rejectCaseFormSchema),
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	console.log(categoryName);

	const onAssignUserFormSubmit = async (assignUserFormData) => {
		const assignUserFormResponse =
			category_id !== "scanner"
				? await assignUser({
						token: token,
						assigned_user: assignUserFormData.user,
						case_number: docId,
						email: email,
						category_id: category_id,
						categoryName: categoryName,
						rowCategory: rowCategory,
						folderName: folderName,
				  })
				: await assignUserInsideScan({
						token: token,
						assigned_user: assignUserFormData.user,
						case_number: docId,
						email: email,
						category_id: category_id,
						categoryName: categoryName,
						rowCategory: rowCategory,
						folderName: folderName,
						document_id: "",
						scan_list_module: "outside",
				  });

		if (assignUserFormResponse.code === 200) {
			setOpen(false);
			setDocumentUpdatedToTrue();
			changeDropdownVisibilityFunc();
			toast.success("Document is assigned successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<div
				onClick={() => {
					handleClickOpen(docId);
				}}
				className="deleteBtn"
			>
				Assign
			</div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="edit-doc-modal">
					<div className="popup-header-svg-icon">
						<TbChecklist />
					</div>
					<div className="popup-header-title">Assign User</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<div style={{ display: "flex", flexDirection: "column" }}>
							<FormControl className="assign-user-doc-select-ui">
								<InputLabel
									className="assign-user-doc-select-label"
									id="select-label"
								>
									User
								</InputLabel>
								<Controller
									control={control}
									name="user"
									render={({ field: { onChange, value } }) => (
										<Select
											name="user"
											sx={{ margin: "10px 0px" }}
											labelId="select-label"
											variant="outlined"
											id="simple-select"
											value={value}
											onChange={onChange}
											label="User"
											{...register("user")}
										>
											{userList.map((user) => (
												<MenuItem key={user.id} value={user.id}>
													{`${user.first_name} ${user.last_name}`}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
							<span className="error-message">{errors["user"]?.message}</span>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ justifyContent: "flex-end" }}>
					<ButtonUI
						formButton={false}
						variant="outlined"
						buttonText="No"
						style={{
							margin: "15px 0px",
							marginTop: "0px",
							backgroundColor: "#fff",
							color: "#007bff",
							border: "1px solid #007bff",
						}}
						onClickFunc={handleClose}
					/>
					<ButtonUI
						variant="outlined"
						buttonText="Yes"
						style={{
							margin: "15px",
							marginTop: "0px",
							backgroundColor: "#fff",
							color: "##007bff",
							border: "1px solid #007bff",
						}}
						autoFocus={true}
						handleSubmit={handleSubmit}
						onFormSubmit={onAssignUserFormSubmit}
					/>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AssignUserPopup;
