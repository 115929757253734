import React, { useState, useContext } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { AuthContext } from "../../../context/Auth/AuthContext";
import AssignUserPopup from "./AssignUserPopup";
import BulkDeletePopup from "./BulkDeletePopup";

const Dropdown = ({
  selectedRowsId,
  selectedRowsEmail,
  selectedFlatRowsName,
  isEntireTableRowsSelected,
  setDocumentUpdatedToTrue,
  setDocumentDeletedToTrue,
  caseList,
  userList,
  folderName,
}) => {
  const [open, setOpen] = useState(false);

  const changeDropdownVisibilityFunc = () => {
    setOpen(!open);
  };

  console.log(selectedRowsEmail);

  return (
    <div className="dropdown-button">
      <IoMdArrowDropdown
        onClick={changeDropdownVisibilityFunc}
        style={{ visibility: selectedRowsId.length > 0 ? "visible" : "hidden" }}
      />
      <div
        className={
          caseList === "scanner"
            ? "scan-doc-elements doc-elements"
            : "doc-elements"
        }
        style={{
          display: open ? "block" : "none",
          zIndex: 10,
          marginTop: "3px",
        }}
      >
        <ul className="docs-dropdown-list">
          <li className="docs-dropdown-item">
            <BulkDeletePopup
              setDocumentDeletedToTrue={setDocumentDeletedToTrue}
              isEntireTableRowsSelected={isEntireTableRowsSelected}
              changeDropdownVisibilityFunc={changeDropdownVisibilityFunc}
              Docbulkid={selectedRowsId}
            />
          </li>
          {caseList === "scanner" ? (
            <li className="docs-dropdown-item">
              <AssignUserPopup
                userList={userList}
                isEntireTableRowsSelected={isEntireTableRowsSelected}
                changeDropdownVisibilityFunc={changeDropdownVisibilityFunc}
                setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                email={selectedRowsEmail}
                list={caseList}
                category_id="scanner"
                folderName={folderName}
                document_id={selectedRowsId}
              />
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
