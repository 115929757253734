import baseUrl from "../config/baseUrl";

const pdfDetails = async (token, documentID) => {
  const formData = new FormData();
  formData.append("document_id", documentID);
  try {
    const response = await fetch(`${baseUrl}/apireact/view_document`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfDetails;
