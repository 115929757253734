import React, { useContext } from "react";
import { toast } from "react-toastify";
import $ from "jquery";
import { fabric } from "fabric";
import { jsPDF } from "jspdf";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "bootstrap";
import { RiPaintFill } from "react-icons/ri";
import { AiTwotoneHighlight } from "react-icons/ai";

import { IoText } from "react-icons/io5";
import { RiPencilFill } from "react-icons/ri";
import { MdDeleteForever } from "react-icons/md";
import { HiDocumentDownload } from "react-icons/hi";
import { TbRectangle } from "react-icons/tb";
import {
  FaRegImage,
  FaSave,
  FaLongArrowAltRight,
  FaRegHandPointer,
} from "react-icons/fa";
import { AuthContext } from "../../context/Auth/AuthContext";
import pdfEditor from "../../api/pdfEditor";
import "../../styles/PdfEditor.css";

const PdfEditor = ({
  pdfBase64,
  documentID,
  documentTitle,
  caseNumber,
  changeActiveTab,
}) => {
  const { token, userID } = useContext(AuthContext);

  const pdfMainData = `data:application/pdf;base64,` + pdfBase64;
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  fabric.LineArrow = fabric.util.createClass(fabric.Line, {
    type: "lineArrow",

    initialize: function (element, options) {
      options || (options = {});
      this.callSuper("initialize", element, options);
    },

    toObject: function () {
      return fabric.util.object.extend(this.callSuper("toObject"));
    },

    _render: function (ctx) {
      this.callSuper("_render", ctx);

      // do not render if width/height are zeros or object is not visible
      if (this.width === 0 || this.height === 0 || !this.visible) return;

      ctx.save();

      let xDiff = this.x2 - this.x1;
      let yDiff = this.y2 - this.y1;
      let angle = Math.atan2(yDiff, xDiff);
      ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
      ctx.rotate(angle);
      ctx.beginPath();
      //move 10px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
      ctx.moveTo(10, 0);
      ctx.lineTo(-20, 15);
      ctx.lineTo(-20, -15);
      ctx.closePath();
      ctx.fillStyle = this.stroke;
      ctx.fill();

      ctx.restore();
    },
  });

  fabric.LineArrow.fromObject = function (object, callback) {
    callback &&
      callback(
        new fabric.LineArrow(
          [object.x1, object.y1, object.x2, object.y2],
          object
        )
      );
  };

  fabric.LineArrow.async = true;

  let Arrow = (function () {
    function Arrow(canvas, color, callback) {
      this.canvas = canvas;
      this.className = "Arrow";
      this.isDrawing = false;
      this.color = color;
      this.callback = callback;
      this.bindEvents();
    }

    Arrow.prototype.bindEvents = function () {
      let inst = this;
      inst.canvas.on("mouse:down", function (o) {
        inst.onMouseDown(o);
      });
      inst.canvas.on("mouse:move", function (o) {
        inst.onMouseMove(o);
      });
      inst.canvas.on("mouse:up", function (o) {
        inst.onMouseUp(o);
      });
      inst.canvas.on("object:moving", function (o) {
        inst.disable();
      });
    };

    Arrow.prototype.unBindEventes = function () {
      let inst = this;
      inst.canvas.off("mouse:down");
      inst.canvas.off("mouse:up");
      inst.canvas.off("mouse:move");
      inst.canvas.off("object:moving");
    };

    Arrow.prototype.onMouseUp = function (o) {
      let inst = this;
      inst.disable();
      inst.unBindEventes();
      if (inst.callback) inst.callback();
    };

    Arrow.prototype.onMouseMove = function (o) {
      let inst = this;
      if (!inst.isEnable()) {
        return;
      }

      let pointer = inst.canvas.getPointer(o.e);
      let activeObj = inst.canvas.getActiveObject();
      activeObj.set({
        x2: pointer.x,
        y2: pointer.y,
      });
      activeObj.setCoords();
      inst.canvas.renderAll();
    };

    Arrow.prototype.onMouseDown = function (o) {
      let inst = this;
      inst.enable();
      let pointer = inst.canvas.getPointer(o.e);

      let points = [pointer.x, pointer.y, pointer.x, pointer.y];
      let line = new fabric.LineArrow(points, {
        strokeWidth: 3,
        fill: inst.color ? inst.color : "red",
        stroke: inst.color ? inst.color : "red",
        originX: "center",
        originY: "center",
        hasBorders: false,
        hasControls: true,
        selectable: true,
      });

      inst.canvas.add(line).setActiveObject(line);
    };

    Arrow.prototype.isEnable = function () {
      return this.isDrawing;
    };

    Arrow.prototype.enable = function () {
      this.isDrawing = true;
    };

    Arrow.prototype.disable = function () {
      this.isDrawing = false;
    };

    return Arrow;
  })();

  let Rectangle = (function () {
    let origX, origY;
    function Rectangle(canvas, color, callback) {
      this.canvas = canvas;
      this.className = "Rectangle";
      this.isDrawing = false;
      this.color = color;
      this.callback = callback;
      this.bindEvents();
    }

    Rectangle.prototype.bindEvents = function () {
      let inst = this;
      inst.canvas.on("mouse:down", function (o) {
        inst.onMouseDown(o);
      });
      inst.canvas.on("mouse:move", function (o) {
        inst.onMouseMove(o);
      });
      inst.canvas.on("mouse:up", function (o) {
        inst.onMouseUp(o);
      });
      inst.canvas.on("object:moving", function (o) {
        inst.disable();
      });
    };

    Rectangle.prototype.unBindEventes = function () {
      let inst = this;
      inst.canvas.off("mouse:down");
      inst.canvas.off("mouse:up");
      inst.canvas.off("mouse:move");
      inst.canvas.off("object:moving");
    };

    Rectangle.prototype.onMouseUp = function (o) {
      let inst = this;
      inst.disable();
      inst.unBindEventes();
      if (inst.callback) inst.callback();
    };

    Rectangle.prototype.onMouseDown = function (o) {
      var inst = this;
      inst.enable();

      var pointer = inst.canvas.getPointer(o.e);
      origX = pointer.x;
      origY = pointer.y;

      var rect = new fabric.Rect({
        left: origX,
        top: origY,
        originX: "left",
        originY: "top",
        width: 0,
        height: 0,
        angle: 0,
        fill: "rgba(0,0,0,0)",
        stroke: inst.color ? inst.color : "red",
        strokeWidth: 2,
      });

      inst.canvas.add(rect).setActiveObject(rect);
    };

    Rectangle.prototype.onMouseMove = function (o) {
      let inst = this;

      if (!inst.isEnable()) {
        return;
      }

      let pointer = inst.canvas.getPointer(o.e);

      let activeObj = inst.canvas.getActiveObject();

      if (origX > pointer.x) {
        activeObj.set({ left: Math.abs(pointer.x) });
      }
      if (origY > pointer.y) {
        activeObj.set({ top: Math.abs(pointer.y) });
      }

      activeObj.set({ width: Math.abs(origX - pointer.x) });
      activeObj.set({ height: Math.abs(origY - pointer.y) });

      activeObj.setCoords();
      inst.canvas.renderAll();
    };

    Rectangle.prototype.isEnable = function () {
      return this.isDrawing;
    };

    Rectangle.prototype.enable = function () {
      this.isDrawing = true;
    };

    Rectangle.prototype.disable = function () {
      this.isDrawing = false;
    };

    return Rectangle;
  })();

  let canvas = new fabric.Canvas("canvas");
  let rect = new Rectangle(canvas);

  let PDFAnnotate = function (container_id, url, options = {}) {
    this.number_of_pages = 0;
    this.pages_rendered = 0;
    this.active_tool = 1; // 1 - Free hand, 2 - Text, 3 - Arrow, 4 - Rectangle
    this.fabricObjects = [];
    this.fabricObjectsData = [];
    this.color = "#212121";
    this.borderColor = "#000000";
    this.borderSize = 1;
    this.font_size = 16;
    this.active_canvas = 0;
    this.container_id = container_id;
    this.url = url;
    this.pageImageCompression = options.pageImageCompression
      ? options.pageImageCompression.toUpperCase()
      : "NONE";
    let inst = this;

    let loadingTask = pdfjsLib.getDocument(this.url);
    loadingTask.promise.then(
      function (pdf) {
        let scale = options.scale ? options.scale : 1.3;
        inst.number_of_pages = pdf.numPages;

        for (let i = 1; i <= pdf.numPages; i++) {
          pdf.getPage(i).then(function (page) {
            let viewport = page.getViewport({
              scale: scale,
            });
            let canvas = document.createElement("canvas");
            document.getElementById(inst.container_id).appendChild(canvas);
            canvas.className = "pdf-canvas";
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            let context = canvas.getContext("2d");

            let renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            let renderTask = page.render(renderContext);
            renderTask.promise.then(function () {
              $(".pdf-canvas").each(function (index, el) {
                $(el).attr("id", "page-" + (index + 1) + "-canvas");
              });
              inst.pages_rendered++;
              if (inst.pages_rendered == inst.number_of_pages)
                inst.initFabric();
            });
          });
        }
      },
      function (reason) {
        console.error({ reason });
      }
    );

    this.initFabric = function () {
      let inst = this;
      let canvases = $("#" + inst.container_id + " canvas");
      canvases.each(function (index, el) {
        let background = el.toDataURL("image/png");
        let fabricObj = new fabric.Canvas(el.id, {
          freeDrawingBrush: {
            width: 1,
            color: inst.color,
          },
        });
        inst.fabricObjects.push(fabricObj);
        if (typeof options.onPageUpdated == "function") {
          fabricObj.on("object:added", function () {
            let oldValue = Object.assign({}, inst.fabricObjectsData[index]);
            inst.fabricObjectsData[index] = fabricObj.toJSON();
            options.onPageUpdated(
              index + 1,
              oldValue,
              inst.fabricObjectsData[index]
            );
          });
        }
        fabricObj.setBackgroundImage(
          background,
          fabricObj.renderAll.bind(fabricObj)
        );
        $(fabricObj.upperCanvasEl).click(function (event) {
          inst.active_canvas = index;
          inst.fabricClickHandler(event, fabricObj);
        });
        fabricObj.on("after:render", function () {
          inst.fabricObjectsData[index] = fabricObj.toJSON();
          fabricObj.off("after:render");
        });

        if (
          index === canvases.length - 1 &&
          typeof options.ready === "function"
        ) {
          options.ready();
        }
      });
    };

    this.fabricClickHandler = function (event, fabricObj) {
      let inst = this;
      if (inst.active_tool == 2) {
        let text = new fabric.IText("Edit text", {
          left:
            event.clientX -
            fabricObj.upperCanvasEl.getBoundingClientRect().left,
          top:
            event.clientY - fabricObj.upperCanvasEl.getBoundingClientRect().top,
          fill: inst.color,
          fontSize: inst.font_size,
          selectable: true,
        });
        fabricObj.add(text);
        inst.active_tool = 0;
      }
    };
  };

  PDFAnnotate.prototype.enableSelector = function () {
    let inst = this;
    inst.active_tool = 0;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
      });
    }
  };

  PDFAnnotate.prototype.enablePencil = function (fnName) {
    let inst = this;
    inst.active_tool = 1;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        if (fnName === "highlighter") {
          fabricObj.freeDrawingBrush.width = 20;
          fabricObj.freeDrawingBrush.color = "rgba(255,255,0,0.4)";
          fabricObj.isDrawingMode = true;
          return;
        }
        fabricObj.freeDrawingBrush.width = 2;
        fabricObj.freeDrawingBrush.color = "black";
        fabricObj.isDrawingMode = true;
      });
    }
  };

  PDFAnnotate.prototype.enableAddText = function () {
    let inst = this;
    inst.active_tool = 2;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
      });
    }
  };

  PDFAnnotate.prototype.enableRectangle = function () {
    let inst = this;
    inst.active_tool = 4;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
        fabricObj.freeDrawingBrush.color = "red";
        new Rectangle(fabricObj, inst.color, function () {
          inst.active_tool = 0;
        });
      });
    }
  };

  PDFAnnotate.prototype.enableAddArrow = function () {
    let inst = this;
    inst.active_tool = 3;
    if (inst.fabricObjects.length > 0) {
      $.each(inst.fabricObjects, function (index, fabricObj) {
        fabricObj.isDrawingMode = false;
        new Arrow(fabricObj, inst.color, function () {
          inst.active_tool = 0;
        });
      });
    }
  };

  PDFAnnotate.prototype.addImageToCanvas = function () {
    let inst = this;
    let fabricObj = inst.fabricObjects[inst.active_canvas];

    if (fabricObj) {
      let inputElement = document.createElement("input");
      inputElement.type = "file";
      inputElement.accept = ".jpg,.jpeg,.png,.PNG,.JPG,.JPEG";
      inputElement.onchange = function () {
        let reader = new FileReader();
        reader.addEventListener(
          "load",
          function () {
            inputElement.remove();
            let image = new Image();
            image.onload = function () {
              fabricObj.add(new fabric.Image(image));
            };
            image.src = this.result;
          },
          false
        );
        reader.readAsDataURL(inputElement.files[0]);
      };
      document.getElementsByTagName("body")[0].appendChild(inputElement);
      inputElement.click();
    }
  };

  PDFAnnotate.prototype.deleteSelectedObject = function () {
    let inst = this;
    let activeObject = inst.fabricObjects[inst.active_canvas].getActiveObject();
    if (activeObject) {
      if (window.confirm("Are you sure you want to delete ?") === true)
        inst.fabricObjects[inst.active_canvas].remove(activeObject);
    }
  };

  PDFAnnotate.prototype.savePdf = async function (fileName, fnName) {
    let inst = this;
    let doc = new jsPDF();
    if (typeof fileName === "undefined") {
      fileName = `${new Date().getTime()}.pdf`;
    }

    inst.fabricObjects.forEach(async function (fabricObj, index) {
      if (index != 0) {
        doc.addPage();
        doc.setPage(index + 1);
      }
      doc.addImage(
        fabricObj.toDataURL({
          format: "png",
        }),
        inst.pageImageCompression == "NONE" ? "PNG" : "JPEG",
        0,
        0,
        doc.internal.pageSize.getWidth(),
        doc.internal.pageSize.getHeight(),
        `page-${index + 1}`,
        ["FAST", "MEDIUM", "SLOW"].indexOf(inst.pageImageCompression) >= 0
          ? inst.pageImageCompression
          : undefined
      );
      if (index === inst.fabricObjects.length - 1) {
        if (fnName === "Download Pdf") {
          doc.save(fileName);
        }

        if (fnName === "Save Pdf") {
          toast.info("Edited document is being uploaded", {
            position: "top-center",
            autoClose: 3000,
            theme: "dark",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          let uploadPdfData = doc.output("blob");

          const savePDFResponse = await pdfEditor({
            token: token,
            case_number: caseNumber,
            document_title: documentTitle,
            document_name: uploadPdfData,
            document_id: documentID,
            user_id: userID,
          });

          if (savePDFResponse?.code === 200) {
            changeActiveTab();
            toast.success("Edited document is uploaded successfully", {
              position: "top-center",
              autoClose: 3000,
              theme: "dark",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      }
    });
  };

  PDFAnnotate.prototype.setBrushSize = function (size) {
    let inst = this;
    $.each(inst.fabricObjects, function (index, fabricObj) {
      fabricObj.freeDrawingBrush.width = size;
    });
  };

  PDFAnnotate.prototype.setColor = function (color) {
    let inst = this;
    inst.color = color;
    inst.active_tool = 0;
    $.each(inst.fabricObjects, function (index, fabricObj) {
      fabricObj.isDrawingMode = false;
      fabricObj.freeDrawingBrush.color = color;
    });
  };

  PDFAnnotate.prototype.setBorderColor = function (color) {
    let inst = this;
    inst.borderColor = color;
  };

  PDFAnnotate.prototype.setFontSize = function (size) {
    this.font_size = size;
  };

  PDFAnnotate.prototype.setBorderSize = function (size) {
    this.borderSize = size;
  };

  // PDFAnnotate.prototype.clearActivePage = function () {
  //   let inst = this;
  //   let fabricObj = inst.fabricObjects[inst.active_canvas];
  //   let bg = fabricObj?.backgroundImage;
  //   if (window.confirm("Are you sure?")) {
  //     fabricObj?.clear();
  //     fabricObj.setBackgroundImage(bg, fabricObj.renderAll.bind(fabricObj));
  //   }
  // };

  PDFAnnotate.prototype.serializePdf = function () {
    let inst = this;
    return JSON.stringify(inst.fabricObjects, null, 4);
  };

  PDFAnnotate.prototype.loadFromJSON = function (jsonData) {
    let inst = this;
    $.each(inst.fabricObjects, function (index, fabricObj) {
      if (jsonData.length > index) {
        fabricObj.loadFromJSON(jsonData[index], function () {
          inst.fabricObjectsData[index] = fabricObj.toJSON();
        });
      }
    });
  };

  let pdf = new PDFAnnotate("pdf-container", pdfMainData, {
    onPageUpdated(page, oldData, newData) {},
    ready() {},
    scale: 1.5,
    pageImageCompression: "MEDIUM", // FAST, MEDIUM, SLOW(Helps to control the new PDF file size)
  });

  function changeActiveTool(event) {
    let element = $(event.target).hasClass("tool-button")
      ? $(event.target)
      : $(event.target).parents(".tool-button").first();
    $(".tool-button.active").removeClass("active");
    $(element).addClass("active");
  }

  function enableSelector(event) {
    event.preventDefault();
    changeActiveTool(event);
    pdf.enableSelector();
  }

  function enablePencil(event, fnName) {
    event.preventDefault();
    changeActiveTool(event);
    pdf.enablePencil(fnName);
  }

  function enableAddText(event) {
    event.preventDefault();
    changeActiveTool(event);
    pdf.enableAddText();
  }

  function enableAddArrow(event) {
    event.preventDefault();
    changeActiveTool(event);
    pdf.enableAddArrow();
  }

  function addImage(event) {
    event.preventDefault();
    pdf.addImageToCanvas();
  }

  function enableRectangle(event) {
    event.preventDefault();
    changeActiveTool(event);
    pdf.enableRectangle();
  }

  function deleteSelectedObject(event) {
    event.preventDefault();
    pdf.deleteSelectedObject();
  }

  function savePDF(fileName, fnName) {
    pdf.savePdf(fileName, fnName);
  }

  // function clearPage() {
  //   pdf.clearActivePage();
  // }

  $(function () {
    $(".color-dropdown").click(function () {
      $(".color-dropdown").toggleClass("active");
      $(".color-dropdown-content").toggleClass("active");
    });

    $(".color-tool").click(function () {
      $(".color-tool.active").removeClass("active");
      $(this).addClass("active");
      let color = $(this).get(0).style.backgroundColor;
      pdf.setColor(color);
    });

    // $("#brush-size").change(function () {
    //   let width = $(this).val();
    //   pdf.setBrushSize(width);
    // });

    pdf.setFontSize(36);

    // $("#font-size").change(function () {
    //   let font_size = $(this).val();
    //   pdf.setFontSize(font_size);
    // });
  });

  return (
    <div className="pdf">
      <div className="toolbar">
        <div className="tool">
          <div className="color-dropdown">
            <div className="color-dropdown-btn">
              <RiPaintFill title="Color" className="icon" />
            </div>
            <div className="color-dropdown-content">
              <button
                className="color-tool active"
                style={{ backgroundColor: "black" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "red" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "blue" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "green" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "yellow" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "grey" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "purple" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "indigo" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "orange" }}
              ></button>
              <button
                className="color-tool"
                style={{
                  backgroundColor: "lightblue",
                }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "cyan" }}
              ></button>
              <button
                className="color-tool"
                style={{ backgroundColor: "rgba(255,255,0,0.5)" }}
              ></button>
            </div>
          </div>
        </div>
        {/* <div className="tool">
          <select
            title="Brush size"
            id="brush-size"
            className="select-font-size"
          >
            <option value="2">2</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </div> */}
        <div className="tool">
          <div
            className="tool-button"
            title="Free Hand"
            onClick={enableSelector}
          >
            <FaRegHandPointer className="icon" />
          </div>
        </div>
        <div className="tool">
          <div
            className="tool-button"
            title="Pencil"
            onClick={(e) => enablePencil(e, "pencil")}
          >
            <RiPencilFill className="icon" />
          </div>
        </div>
        <div className="tool">
          <div
            className="tool-button"
            title="Highlighter"
            onClick={(e) => enablePencil(e, "highlighter")}
          >
            <AiTwotoneHighlight className="icon" />
          </div>
        </div>
        <div className="tool">
          <div className="tool-button" title="Add Text" onClick={enableAddText}>
            <IoText className="icon" />
          </div>
        </div>
        <div className="tool">
          <div
            className="tool-button"
            title="Add Arrow"
            onClick={enableAddArrow}
          >
            <FaLongArrowAltRight className="icon" />
          </div>
        </div>
        <div className="tool">
          <div
            className="tool-button"
            title="Add Rectangle"
            onClick={enableRectangle}
          >
            <TbRectangle className="icon" />
          </div>
        </div>
        <div className="tool">
          <div className="tool-button" title="Add an Image" onClick={addImage}>
            <FaRegImage className="icon" />
          </div>
        </div>
        <div className="tool">
          <div
            className="tool-button"
            title="Delete Item"
            onClick={deleteSelectedObject}
          >
            <MdDeleteForever className="icon" />
          </div>
        </div>
        {/* <div className="tool">
          <div className="tool-button" title="Clear Page" onClick={clearPage}>
            <HiDocumentRemove className="icon" />
          </div>
        </div> */}
        <div className="tool">
          <div
            className="tool-button"
            title="Download PDF"
            onClick={() => savePDF(documentTitle, "Download Pdf")}
          >
            <HiDocumentDownload className="icon" />
          </div>
        </div>
        <div className="tool">
          <div
            className="tool-button"
            title="Save PDF"
            onClick={() => savePDF(documentTitle, "Save Pdf")}
          >
            <FaSave className="icon" />
          </div>
        </div>
      </div>
      <div id="pdf-container"></div>
    </div>
  );
};

export default PdfEditor;
