import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { IoMdArrowDropdown } from "react-icons/io";
import { CSVLink } from "react-csv";

const Dropdown = ({ selectedRows, getAllDataFunc, docsCount }) => {
  const [open, setOpen] = useState(false);
  const [allRecords, setAllRecords] = useState([]);

  const csvRef = useRef();

  const changeDropdownVisibilityFunc = () => {
    setOpen(!open);
  };

  const headers = [
    { label: "Case Number", key: "case_number" },
    { label: "Name", key: "title" },
    { label: "File", key: "extension" },
    { label: "Category", key: "category" },
    { label: "Source", key: "uncategorized_flag" },
    { label: "Date Uploaded", key: "date_entered" },
    { label: "Date Modified", key: "date_modified" },
    { label: "Modified By", key: "modified_user_name" },
  ];

  const data = selectedRows;

  const onAllRecordsButtonClick = async () => {
    toast.success("CSV will be downlaoded soon", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    const data = await getAllDataFunc();
    setAllRecords(data);
    setTimeout(() => {
      csvRef.current.link.click();
    }, 1000);
    setOpen(false);
  };

  return (
    <div className="dropdown-button">
      <IoMdArrowDropdown
        onClick={changeDropdownVisibilityFunc}
        style={{ visibility: selectedRows.length > 0 ? "visible" : "hidden" }}
      />
      <div
        className="reports-elements"
        style={{
          display: open ? "block" : "none",
          zIndex: 10,
          marginTop: "3px",
        }}
      >
        <ul className="reports-dropdown-list">
          <li className="reports-dropdown-item">
            <CSVLink
              style={{ color: "black" }}
              data={data}
              headers={headers}
              filename={"reports.csv"}
              onClick={() => {
                setOpen(false);
              }}
            >
              {`Download ${selectedRows.length} Record CSV`}
            </CSVLink>
          </li>
          <CSVLink
            style={{ color: "yellow" }}
            data={allRecords}
            headers={headers}
            filename={"reports.csv"}
            ref={csvRef}
          />
          <li
            className="reports-dropdown-item"
            onClick={onAllRecordsButtonClick}
          >
            {`Download ${docsCount} Record CSV`}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
