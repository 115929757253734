import baseUrl from "../config/baseUrl";

const lineChart = async ({ token, userID, isAdmin }) => {
  try {
    const response = await fetch(
      `${baseUrl}/apireact/dashboard_get_processed_weekly_document_graph_data?isAdmin=${isAdmin}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default lineChart;
