import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addUser from "../../api/addUser";
import addUserImg from "../../images/add-user.png";
import "../../styles/AddDocument.css";

const phoneRegExp = /^[2-9]\d{9}$/;

const addUserFormSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required field"),
  lastName: yup.string().required("Last Name is required field"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required field"),
  role: yup.string().required("Role is required field"),
});

const AddUser = ({ onAddUserButtonClick, setUserAddedToTrue }) => {
  const [loading, setLoading] = useState(false);
  const { token, userID } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addUserFormSchema),
  });

  const roleOptions = [
    {
      name: "Admin",
      value: 1,
    },
    {
      name: "Non Admin",
      value: 0,
    },
    {
      name: "Read only",
      value: 2,
    },
  ];

  const onAddUserFormSubmit = async (addUserFormData) => {
    setLoading(true);

    toast.info("User is being added", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const addUserFormResponse = await addUser({
      token: token,
      userId: userID,
      first_name: addUserFormData.firstName,
      last_name: addUserFormData.lastName,
      user_email: addUserFormData.email,
      is_admin: addUserFormData.role,
    });

    if (addUserFormResponse?.code === 200) {
      setLoading(false);
      onAddUserButtonClick();
      setUserAddedToTrue();
      toast.success("User is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (addUserFormResponse?.code === 401) {
      setLoading(false);
      toast.error("Email Already Exists", {
        position: "top-center",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="add-document-page">
      <div className="add-document-container">
        <div className="add-document-header">
          <div className="add-document-title-with-img">
            <img src={addUserImg} height="100px" width="120px" alt="Add User" />
            <div className="add-document-title">Add New User</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddUserButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-document-form">
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="firstName"
                label="Enter First Name"
                type="text"
                variant="outlined"
                cssStyles={{
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="error-message">
                {errors["firstName"]?.message}
              </span>
            </div>
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="lastName"
                label="Enter Last Name"
                type="text"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">
                {errors["lastName"]?.message}
              </span>
            </div>
          </div>
          <div className="add-document-inputs">
            <div className="add-document-inputs-with-error-message">
              <InputUI
                name="email"
                label="Enter Email ID"
                type="email"
                variant="outlined"
                cssStyles={{ margin: "10px 0px" }}
                register={register}
              />
              <span className="error-message">{errors["email"]?.message}</span>
            </div>
            <div className="add-document-inputs-with-error-message select-ui">
              <FormControl fullWidth>
                <InputLabel id="select-label">Role</InputLabel>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="role"
                      sx={{ margin: "10px 0px" }}
                      labelId="select-label"
                      variant="outlined"
                      id="simple-select"
                      value={value}
                      onChange={onChange}
                      label="Role"
                      {...register("role")}
                    >
                      {roleOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <span className="error-message">{errors["role"]?.message}</span>
            </div>
          </div>
          <div className="add-document-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add User"
              style={{
                width: "150px",
                height: "35px",
                color: "#fff",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddUserFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
