import React, { useState, useContext, useEffect } from "react";
import ReactPaginate from "react-paginate";
import TextField from "@mui/material/TextField";
import { AiOutlineSearch } from "react-icons/ai";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Navbar from "../components/common/Navbar";
import SideBar from "../components/common/Sidebar";
import recentDocuments from "../api/recents";
import RecentsTable from "../components/recent-document-table/RecentsTable";
import Loading from "../components/material-ui/Loading";
import { AuthContext } from "../context/Auth/AuthContext";
import baseUrl from "../config/baseUrl";
import "../styles/Recents.css";

const Recents = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [recentDocumentsResponse, setRecentDocumentsResponse] = useState([]);
  const [globalSearch, setGlobalSearch] = useState("");
  const { token, userID, isAdmin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/apireact/allrecent?limit=20&offset=0&isAdmin=${isAdmin}&user_id=${userID}`
  );

  let limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";

  const fetchRecentDocuments = async () => {
    setLoading(true);
    const response = await recentDocuments({ token, api });
    setPageCount(Math.ceil(response?.allResentCount / limit));
    setRecentDocumentsResponse(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchRecentDocuments();
  }, [api]);

  useEffect(() => {
    setApi(
      `${baseUrl}/apireact/allrecent?limit=${limit}&offset=${offset}${global_search}&isAdmin=${isAdmin}&user_id=${userID}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/apireact/allrecent?limit=${20}&offset=${0}${global_search}&isAdmin=${isAdmin}&user_id=${userID}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="recent-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="recent-content">
        <div className="recent-table-header">
          <TextField
            name="globalSearch"
            label="Search"
            type="text"
            autoComplete="off"
            value={globalSearch}
            onChange={onGlobalSearchChange}
            variant="outlined"
            InputProps={{
              endAdornment: <AiOutlineSearch />,
            }}
          />
        </div>
        {!loading ? (
          recentDocumentsResponse.length > 0 ? (
            <div className="recent-table-content">
              <RecentsTable
                loading={loading}
                getRecentDocuments={recentDocumentsResponse}
              />
              <div className="recent-table-pagination">
                <ReactPaginate
                  previousLabel={<MdOutlineArrowBackIos />}
                  nextLabel={<MdOutlineArrowForwardIos />}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"recent-pagination"}
                  previousLinkClassName={"recent-pagination-link"}
                  nextLinkClassName={"recent-pagination-link"}
                  disabledClassName={"recent-pagination-link-disabled"}
                  activeClassName={"recent-pagination-link-active"}
                />
              </div>
            </div>
          ) : (
            <h5 className="no-recent-data">No Data Available</h5>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Recents;
