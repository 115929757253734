import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const LineChart = ({ processedListResponse }) => {
  const labels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const dataValue = [
    processedListResponse.Monday,
    processedListResponse.Tuesday,
    processedListResponse.Wednesday,
    processedListResponse.Thursday,
    processedListResponse.Friday,
    processedListResponse.Saturday,
    processedListResponse.Sunday,
  ];

  console.log(dataValue);

  const data = {
    labels,
    datasets: [
      {
        label: "Cases",
        data: dataValue,
        borderColor: "rgb(69, 205, 85)",
        backgroundColor: "rgb(69, 205, 85)",
        tension: 0.4,
      },
    ],
  };

  return (
    <Line
      data={data}
      options={{
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          datalabels: {
            display: false,

            anchor: "start",
            align: "right",
            font: {
              size: 14,
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              padding: 50,
              margin: 50,
            },
          },
          title: {
            display: true,
            text: "Processed Case List",
            padding: {
              top: 0,
              bottom: 30,
            },
            font: { size: 18 },
            color: "#007bff",
          },
        },
      }}
    />
  );
};

export default LineChart;
