import React, { useEffect, useMemo } from "react";
import { format } from "date-fns";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { Columns } from "./Column";
import RestoreDocument from "./RestoreDocument";
import PermanentlyDeleteDocument from "./PermanentlyDeleteDocument";
import Dropdown from "./Dropdown";
import { fileIcon } from "../../common/FileIcons";
import { categories } from "../../common/Categories";
import Loading from "../../material-ui/Loading";
import "../../../styles/Table.css";
import CheckBox from "./CheckBox";
import { useContext } from "react";
import { AuthContext } from "../../../context/Auth/AuthContext";

const Table = ({
  loading,
  setDocumentRestoredToTrue,
  setDocumentDeletedToTrue,
  trashedDocuments,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => trashedDocuments, [trashedDocuments]);

  const { isAdmin } = useContext(AuthContext);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      ...(isAdmin !== "2"
        ? [
            {
              Header: "Action",
              Cell: ({ row }) => (
                <>
                  <RestoreDocument
                    setDocumentRestoredToTrue={setDocumentRestoredToTrue}
                    id={row.values.documentID}
                  />
                  {isAdmin === "1" ? (
                    <PermanentlyDeleteDocument
                      setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                      id={row.values.documentID}
                    />
                  ) : null}
                </>
              ),
            },
          ]
        : []),
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: ["documentID", "bg_color"],
      },
    },
    tableHooks,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map(
    (row) => `'${row.values.documentID}'`
  );
  const documentTableDataID = trashedDocuments.map((row) => "tableData");

  console.log(selectedFlatRowsID);

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === documentTableDataID.length;

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setDocumentRestoredToTrue={setDocumentRestoredToTrue}
          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
        />
      </div>
    );
  };

  return (
    <div className="trash-doc-table-content table-content">
      {!loading ? (
        <div>
          <table
            className="documents-table deleted-documents-tables"
            {...getTableProps()}
          >
            <thead className="documents-table-header">
              {headerGroups.map((headerGroup, index) => (
                <tr
                  key={index}
                  className="documents-table-header-row"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, index) => {
                    return (
                      <th
                        key={index}
                        className="documents-table-header-data"
                        {...column.getHeaderProps()}
                      >
                        {column.id == "selection" ? (
                          <SelectionHeader
                            getToggleAllRowsSelectedProps={
                              getToggleAllRowsSelectedProps
                            }
                          />
                        ) : (
                          column.render("Header")
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="documents-table-body" {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    key={index}
                    className="document-table-body-row"
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, index) => {
                      switch (cell.column.Header) {
                        case "File":
                          return (
                            <td key={index} className="file-icon">
                              {fileIcon[cell.value]}
                            </td>
                          );
                        case "Case ID":
                          return (
                            <td key={index}>
                              {cell.value !== "" ? cell.value : "N/A"}
                            </td>
                          );
                        case "Category":
                          return (
                            <td key={index}>
                              <p
                                className="category"
                                style={{
                                  background: `${
                                    cell.row.values.bg_color ||
                                    "rgb(203, 190, 196)"
                                  }`,
                                }}
                              >
                                {cell.row.values.badge || "Other"}
                              </p>
                            </td>
                          );

                        case "Uploaded":
                          return (
                            <td key={index}>
                              {cell.value !== "0000-00-00" &&
                              cell.value !== "0000-00-00 00:00:00"
                                ? format(
                                    new Date(cell.value),
                                    "MM-dd-yyyy HH:mm"
                                  )
                                : "N/A"}
                            </td>
                          );

                        default:
                          return (
                            <td key={index} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;
