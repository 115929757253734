import baseUrl from "../config/baseUrl";

const assignProcessedDoc = async ({ token, id }) => {
  const formData = new FormData();
  formData.append("document_ids", id);

  try {
    const response = await fetch(`${baseUrl}/caselist/bulk_proccessed_manually_moved_service_docs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default assignProcessedDoc;