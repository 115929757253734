import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AiFillCloseCircle } from "react-icons/ai";
import InputUI from "../material-ui/InputUI";
import ButtonUI from "../material-ui/ButtonUI";
import { AuthContext } from "../../context/Auth/AuthContext";
import addCategory from "../../api/addCategory";
import "../../styles/AddCategory.css";
import addCategoryImg from "../../images/edit-category.png";

const addCategoryFormSchema = yup.object().shape({
  name: yup.string().required("Category Name is required field"),
  description: yup.string().required("Description is required field"),
  badge: yup.string().required("Badge name is required field"),
  bgColor: yup.string(),
});

const AddCategory = ({ onAddCategoryButtonClick, setCategoryAddedToTrue }) => {
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#d7cbef");
  const { token, userID } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addCategoryFormSchema),
  });

  const onAddCategoryFormSubmit = async (addCategoryFormData) => {
    setLoading(true);

    toast.info("Category is being added", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    const addCategoryFormResponse = await addCategory({
      token: token,
      user_id: userID,
      name: addCategoryFormData.name,
      description: addCategoryFormData.description,
      badge: addCategoryFormData.badge,
      bgColor: addCategoryFormData.bgColor,
    });

    if (addCategoryFormResponse?.code === 200) {
      setLoading(false);
      onAddCategoryButtonClick();
      setCategoryAddedToTrue();
      toast.success("Category is added successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (addCategoryFormResponse?.code === 401) {
      setLoading(false);
      onAddCategoryButtonClick();
      setCategoryAddedToTrue();
      toast.success("Category already exist", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    if (
      addCategoryFormResponse?.errors?.name[0] ||
      addCategoryFormResponse?.errors?.description[0] ||
      addCategoryFormResponse?.errors?.createdBy[0]
    ) {
      setLoading(false);
      toast.error("Every field is required", {
        position: "bottom-right",
        autoClose: 5000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const changeBgColor = (e) => {
    setColor(e.target.value);
  };

  return (
    <div className="add-category-page">
      <div className="add-category-container">
        <div className="add-category-header">
          <div className="add-category-title-with-img">
            <img
              src={addCategoryImg}
              height="100px"
              width="120px"
              alt="Add Category"
            />
            <div className="add-category-title">Add New Category</div>
          </div>
          <ButtonUI
            formButton={false}
            variant="contained"
            buttonText={<AiFillCloseCircle />}
            style={{
              background: "none",
              padding: "0px",
              minWidth: "auto",
            }}
            onClickFunc={onAddCategoryButtonClick}
          ></ButtonUI>
        </div>
        <div className="add-category-form">
          <div className="add-category-inputs">
            <div className="add-category-inputs-with-error-message">
              <InputUI
                name="name"
                label="Category Name"
                type="text"
                variant="outlined"
                cssStyles={{
                  width: "100%",
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="add-category-error-message">
                {errors["name"]?.message}
              </span>
            </div>
            <div className="add-category-inputs-with-error-message">
              <InputUI
                name="description"
                label="Description"
                type="text"
                variant="outlined"
                cssStyles={{
                  width: "100%",
                  margin: "10px 0px",
                }}
                register={register}
              />
              <span className="add-category-error-message">
                {errors["description"]?.message}
              </span>
            </div>
            <div className="category-with-color">
              <div className="add-category-inputs-with-error-message">
                <InputUI
                  name="badge"
                  label="Badge"
                  type="text"
                  variant="outlined"
                  cssStyles={{
                    width: "100%",
                    margin: "10px 0px",
                  }}
                  register={register}
                />
                <span className="add-category-error-message">
                  {errors["badge"]?.message}
                </span>
              </div>
              <input
                {...register("bgColor")}
                name="bgColor"
                type="color"
                className="add-category-bg-color"
                onChange={changeBgColor}
                value={color}
              />
            </div>
          </div>
          <div className="add-category-button">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Add Category"
              style={{
                width: "150px",
                height: "35px",
                backgroundColor: "#007bff",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
                marginTop: "20px",
              }}
              handleSubmit={handleSubmit}
              onFormSubmit={onAddCategoryFormSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategory;
