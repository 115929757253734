import React, { useContext, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Grid } from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import AvatarUI from "../components/material-ui/AvatarUI";
import { loginRequest } from "../authConfig";
import ButtonUI from "../components/material-ui/ButtonUI";
import loginIllustration from "../images/data.png";
import illustrationBg from "../images/illustration_bg.png";
import { AuthContext } from "../context/Auth/AuthContext";
import main_bg from "../images/login_bg.png";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css";

const Login = () => {
  const { instance } = useMsal();
  const redirect = useNavigate();

  function handleLogin(instance) {
    instance
      .loginPopup()
      .then((data) => {
        redirect("/callback");
      })
      .catch((e) => {
        console.error(e);
      });
  }

  return (
    <Grid className="login" style={{ backgroundImage: `url(${main_bg})` }}>
      <div className="login-container">
        <div
          className="illustration_wrapper"
          style={{ backgroundImage: `url(${illustrationBg})` }}
        >
          <img src={loginIllustration} />
        </div>
        <div className="login_form_wrapper">
          <AvatarUI>
            <AccountCircleRoundedIcon />
          </AvatarUI>
          <h2>Hello Again!</h2>

          <div className="login_btn">
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Login"
              onClickFunc={() => handleLogin(instance)}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default Login;
