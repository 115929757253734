import React, { useMemo } from "react";
import { format } from "date-fns";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { Columns } from "./Columns";
import Loading from "../material-ui/Loading";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import { fileIcon } from "../common/FileIcons";
import "../../styles/Table.css";

const Table = ({
  loading,
  openFilter,
  documentTableData,
  getAllDataFunc,
  docsCount,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(
    () =>
      documentTableData.map((table) => {
        return table;
      }),
    [documentTableData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: [
          "documentID",
          "favourite",
          "document_desc",
          "bg_color",
          "category",
          "name",
        ],
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },

          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map((row) => {
    return {
      case_number:
        row.values.case_number !== "" ? row.values.case_number : "N/A",
      title: row.values.title,
      extension: row.values.extension,
      category: row.values.category,
      uncategorized_flag:
        row.values.uncategorized_flag === "S11111"
          ? "Scan"
          : row.values.uncategorized_flag === "F22222"
          ? "Fax"
          : row.values.uncategorized_flag === "E33333"
          ? "Email"
          : "Service Docs",
      date_entered:
        row.values.date_entered !== "0000-00-00" &&
        row.values.date_entered !== "0000-00-00 00:00:00"
          ? format(new Date(row.values.date_entered), "MM-dd-yyyy HH:mm")
          : "N/A",
      date_modified:
        row.values.date_modified !== "0000-00-00" &&
        row.values.date_modified !== "0000-00-00 00:00:00"
          ? format(new Date(row.values.date_modified), "MM-dd-yyyy HH:mm")
          : "N/A",
      modified_user_name:
        row.values.modified_user_name !== null
          ? row.values.modified_user_name
          : "N/A",
    };
  });

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          getAllDataFunc={getAllDataFunc}
          docsCount={docsCount}
        />
      </div>
    );
  };

  return (
    <div className={!openFilter ? "table-content" : "resized-table-content"}>
      {!loading ? (
        <table className="documents-table report-table" {...getTableProps()}>
          <thead className="documents-table-header">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="documents-table-header-row"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="documents-table-header-data"
                      key={index}
                    >
                      <span className="document-table-select-row">
                        {column.id === "selection" ? (
                          <SelectionHeader
                            getToggleAllRowsSelectedProps={
                              getToggleAllRowsSelectedProps
                            }
                          />
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            {column.render("Header")}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "5px",
                                fontSize: "16px",
                              }}
                            >
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <IoMdArrowDropdown />
                                ) : (
                                  <IoMdArrowDropup />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="documents-table-body" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  className="document-table-body-row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    switch (cell.column.Header) {
                      case "File":
                        return (
                          <td key={index} className="file-icon">
                            {fileIcon[cell.value]}
                          </td>
                        );
                      case "Case ID":
                        return (
                          <td key={index}>
                            {cell.value !== "" ? cell.value : "N/A"}
                          </td>
                        );

                      case "Category":
                        return (
                          <td key={index}>
                            <p
                              className="category"
                              style={{
                                background: `${
                                  cell.row.values.bg_color ||
                                  "rgb(203, 190, 196)"
                                }`,
                              }}
                            >
                              {cell.row.values.badge || "Other"}
                            </p>
                          </td>
                        );

                      case "Source":
                        return (
                          <td key={index}>
                            {cell.value === "S11111"
                              ? "Scan"
                              : cell.value === "F22222"
                              ? "Fax"
                              : cell.value === "E33333"
                              ? "Email"
                              : "Service Docs"}
                          </td>
                        );

                      case "Modified By":
                        return (
                          <td key={index}>{cell.value ? cell.value : "N/A"}</td>
                        );

                      case "Uploaded":
                        return (
                          <td key={index}>
                            {cell.value !== "0000-00-00" &&
                            cell.value !== "0000-00-00 00:00:00"
                              ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                              : "N/A"}
                          </td>
                        );

                      case "Modified At":
                        return (
                          <td key={index}>
                            {cell.value !== "0000-00-00" &&
                            cell.value !== "0000-00-00 00:00:00"
                              ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                              : "N/A"}
                          </td>
                        );

                      case "Tax Year":
                        return (
                          <td key={index}>
                            {cell.value !== "" ? cell.value : "N/A"}
                          </td>
                        );

                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;
