import baseUrl from "../config/baseUrl";

const notificationData = async ({ token, userID, isAdmin }) => {
  try {
    const response = await fetch(
      `${baseUrl}/apireact/get_notification?userId=${userID}&isAdmin=${isAdmin}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    return data;
  } catch (error) {
    return error;
  }
};

export default notificationData;
