import React, { useState, useContext } from "react";
import { MdDelete } from "react-icons/md";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import ButtonUI from "../material-ui/ButtonUI";
import deleteDocument from "../../api/deleteDocument";
import { AuthContext } from "../../context/Auth/AuthContext";

const DeleteDocumentPopup = ({ setDocumentDeletedToTrue, id }) => {
	const [open, setOpen] = useState(false);
	const { token, userID } = useContext(AuthContext);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onDeleteDocumentFormSubmit = async () => {
		const deleteDocumentFormResponse = await deleteDocument({
			token: token,
			id: id,
			user_id: userID,
		});

		if (deleteDocumentFormResponse?.code === 200) {
			setDocumentDeletedToTrue(false);
			setOpen(false);
			toast.success("Document is deleted successfully", {
				position: "top-center",
				autoClose: 3000,
				theme: "dark",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<div className="dialog-modal">
			<ButtonUI
				formButton={false}
				variant="outlined"
				buttonText={<MdDelete />}
				style={{
					backgroundColor: "#dc3545",
					color: "white",
				}}
				onClickFunc={handleClickOpen}
			/>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="edit-doc-modal">
					<div className="popup-header-svg-icon">
						<MdDelete />
					</div>
					<div className="popup-header-title">Delete Document</div>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this document ?
					</DialogContentText>
				</DialogContent>
				<DialogActions style={{ justifyContent: "flex-end" }}>
					<ButtonUI
						formButton={false}
						variant="outlined"
						buttonText="No"
						style={{
							margin: "15px 0px",
							backgroundColor: "#fff",
							color: "#007bff",
							border: "1px solid #007bff",
						}}
						onClickFunc={handleClose}
					/>
					<ButtonUI
						formButton={false}
						variant="outlined"
						buttonText="Yes"
						style={{
							margin: "15px",
							backgroundColor: "#fff",
							color: "##007bff",
							border: "1px solid #007bff",
						}}
						autoFocus={true}
						onClickFunc={() => {
							onDeleteDocumentFormSubmit();
						}}
					/>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DeleteDocumentPopup;
