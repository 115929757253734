import baseUrl from "../config/baseUrl";

const permanentlyDeleteDocument = async ({ token, id }) => {
  const formData = new FormData();
  formData.append("document_id", id);
  try {
    const response = await fetch(
      `${baseUrl}/apireact/permanent_documents_delete`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default permanentlyDeleteDocument;
