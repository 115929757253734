import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import { TbChecklist } from "react-icons/tb";
import { HiOutlineClipboardList, HiOutlineMail } from "react-icons/hi";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";
import Loading from "../components/material-ui/Loading";
import Navbar from "../components/common/Navbar";
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";
import { AuthContext } from "../context/Auth/AuthContext";
import dashboardData from "../api/dashboard";
import documentChart from "../api/documentChart";
import "../styles/Dashboard.css";
import LineChart from "../components/charts/LineChart";
import allAssignedChart from "../api/allAssignedChart";
import lineChart from "../api/lineChart";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  const [dashboardResponse, setDashboardResponse] = useState({});
  const [allAssignedUserResponse, setAllAssignedUserResponse] = useState({});
  const [processedListResponse, setProcessedListResponseFunc] = useState({});
  const [processedUserResponse, setProcessedUserResponse] = useState({});
  const { token, userID, isAdmin } = useContext(AuthContext);

  const dashboardAPIData = async () => {
    const dashboardResponseFunc = async () => {
      const response = await dashboardData({ token, userID, isAdmin });
      setDashboardResponse(response);
    };

    const processedListResponseFunc = async () => {
      const response = await lineChart({ token, userID, isAdmin });
      const data = await response?.dashboardDocumentData[0];
      setProcessedListResponseFunc(data);
    };

    const allAssignedUserResponseFunc = async () => {
      const response = await allAssignedChart({ token, isAdmin });
      const data = await response?.dashboardDocumentData;
      console.log(data);
      const label = [];
      const dataset = [];
      data.forEach((data) => {
        label.push(data.user_name);
        dataset.push(data.totalCount);
      });
      console.log(label, dataset);
      setAllAssignedUserResponse({
        label: label,
        dataset: dataset,
      });
    };

    const documentUploadedResponseFunc = async () => {
      const response = await documentChart({ token, userID, isAdmin });
      const data = await response?.dashboardDocumentData;
      setProcessedUserResponse(data);
    };

    await dashboardResponseFunc();
    await processedListResponseFunc();
    await allAssignedUserResponseFunc();
    await documentUploadedResponseFunc();
    setLoading(false);
  };

  useEffect(() => {
    dashboardAPIData();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="navbar">
        <Navbar />
      </div>
      {!loading ? (
        <div className="dashboard-page-content">
          <div className="dahboard-column">
            <div className="dashboard-column-section">
              <Link to="/unassigned-case-list" className="dashboard-stats">
                <div className="data-count">
                  {dashboardResponse?.all_un_assigned_document_email_count}
                </div>
                <div className="data-with-icon">
                  <div>
                    <HiOutlineClipboardList />
                  </div>
                  <div className="data">Un Assigned</div>
                </div>
              </Link>
              {isAdmin === "1" ? (
                <Link to="/all-assigned-case-list" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.all_assigned_document_email_count}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <TbChecklist />
                    </div>
                    <div className="data">All Assigned</div>
                  </div>
                </Link>
              ) : (
                <div className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.all_assigned_document_email_count}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <TbChecklist />
                    </div>
                    <div className="data">All Assigned</div>
                  </div>
                </div>
              )}
              <Link to="/assigned-case-list" className="dashboard-stats">
                <div className="data-count">
                  {dashboardResponse?.all_my_assigned_document_email_count}
                </div>
                <div className="data-with-icon">
                  <div>
                    <TbChecklist />
                  </div>
                  <div className="data">My Assigned</div>
                </div>
              </Link>
              <Link to="/my-processed-case-list" className="dashboard-stats">
                <div className="data-count">
                  {dashboardResponse?.my_proccessed_document_email_count}
                </div>
                <div className="data-with-icon">
                  <div>
                    <CgFileDocument />
                  </div>
                  <div className="data">Processed</div>
                </div>
              </Link>
            </div>
            <div className="dashboard-column-section">
              {isAdmin === "1" ? (
                <Link to="/client-unknown-category" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.client_unknown_document_email_count}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <HiOutlineClipboardList />
                    </div>
                    <div className="data">Client Unknown</div>
                  </div>
                </Link>
              ) : (
                <div className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.client_unknown_document_email_count}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <HiOutlineClipboardList />
                    </div>
                    <div className="data">Client Unknown</div>
                  </div>
                </div>
              )}
              <Link to="/email-case-list" className="dashboard-stats">
                <div className="data-count">
                  {dashboardResponse?.email_document_email_count}
                </div>
                <div className="data-with-icon">
                  <div>
                    <HiOutlineMail />
                  </div>
                  <div className="data">Email</div>
                </div>
              </Link>
              <Link to="/scan-case-list" className="dashboard-stats">
                <div className="data-count">
                  {dashboardResponse?.scanner_document_email_count}
                </div>
                <div className="data-with-icon">
                  <div>
                    <BiMessageSquareDetail />
                  </div>
                  <div className="data">Scan</div>
                </div>
              </Link>
              <Link to="/fax-case-list" className="dashboard-stats">
                <div className="data-count">
                  {dashboardResponse?.fax_document_email_count}
                </div>
                <div className="data-with-icon">
                  <div>
                    <MdOutlineDocumentScanner />
                  </div>
                  <div className="data">Fax</div>
                </div>
              </Link>
            </div>
          </div>
          <div className="dashboard-column-2">
            <div className="linechart">
              <LineChart processedListResponse={processedListResponse} />
            </div>
            <div className="piechart">
              <PieChart allAssignedUserResponse={allAssignedUserResponse} />
            </div>
          </div>
          <div className="dashboard-column-3">
            <BarChart processedUserResponse={processedUserResponse} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;
