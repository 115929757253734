import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable, useRowSelect } from "react-table";
import { Columns } from "./Column";
import CopyLinkPopup from "./CopyLinkPopup";
import DocumentViewer from "./DocumentViewer";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import { fileIcon } from "../../common/FileIcons";
import DeleteDocumentPopup from "./DeleteDocumentPopup";
import EditDocumentPopup from "./EditDocumentPopup";
import "../../../styles/Table.css";
import { AuthContext } from "../../../context/Auth/AuthContext";

const AllDocumentsTable = ({
  caseNo,
  documentResponse,
  categoryOption,
  setDocumentUpdatedToTrue,
  setDocumentDeletedToTrue,
  changeDocumentViewerState,
  caseList,
  userList,
  categoryName,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => documentResponse, [documentResponse]);

  const { isAdmin } = useContext(AuthContext);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            {isAdmin === "2" ? (
              <DocumentViewer
                tableValues={row.values}
                changeDocumentViewerState={changeDocumentViewerState}
              />
            ) : (
              <>
                <EditDocumentPopup
                  tableValues={row.values}
                  categoryOption={categoryOption}
                  setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                />
                <CopyLinkPopup caseNo={caseNo} tableValues={row.values} />
                <DocumentViewer
                  tableValues={row.values}
                  changeDocumentViewerState={changeDocumentViewerState}
                />
                <DeleteDocumentPopup
                  tableValues={row.values}
                  setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                />
              </>
            )}
          </>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns:
          categoryName === "Fax"
            ? [
                "documentID",
                "favourite",
                "document_desc",
                "bg_color",
                "name",
                "category",
                "uncategorized_from_name",
                "uncategorized_email",
              ]
            : [
                "documentID",
                "favourite",
                "document_desc",
                "bg_color",
                "name",
                "category",
                "uncategorized_from_name",
                "uncategorized_email",
                "subfolder_name",
              ],
      },
    },
    tableHooks,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <span
                style={{
                  visibility:
                    (isAdmin === "1" && caseList !== "all") ||
                    caseList === "all"
                      ? "visible"
                      : "hidden",
                }}
              >
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </span>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map(
    (row) => `'${row.values.documentID}'`
  );
  const selectedFlatRowsEmail = selectedFlatRows.map(
    (row) => `'${row.values.uncategorized_email}'`
  );
  const selectedFlatRowsFolderName = selectedFlatRows.map(
    (row) => `'${row.values.uncategorized_from_name}'`
  );
  const selectedFlatRowsTitle = selectedFlatRows.map(
    (row) => `'${row.values.title}'`
  );

  const documentTableDataID = documentResponse.map((row) => "tableData");

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === documentTableDataID.length;

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRowsId={selectedFlatRowsID}
          selectedRowsEmail={selectedFlatRowsEmail}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
          caseList={caseList}
          userList={userList}
          folderName={selectedFlatRowsFolderName}
        />
      </div>
    );
  };

  return (
    <div className="table-content">
      <table className="documents-table all-doc-tables" {...getTableProps()}>
        <thead className="documents-table-header">
          {headerGroups.map((headerGroup, index) => (
            <tr
              className="documents-table-header-row"
              key={index}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <th className="documents-table-header-data" key={index}>
                    <span className="document-table-select-row">
                      {column.id == "selection" ? (
                        <span
                          style={{
                            visibility:
                              (isAdmin === "1" && caseList !== "all") ||
                              caseList === "all"
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          <SelectionHeader
                            getToggleAllRowsSelectedProps={
                              getToggleAllRowsSelectedProps
                            }
                          />
                        </span>
                      ) : (
                        column.render("Header")
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="documents-table-body" {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                key={index}
                className="document-table-body-row"
                {...row.getRowProps()}
              >
                {row.cells.map((cell, index) => {
                  switch (cell.column.Header) {
                    case "File":
                      return (
                        <td key={index} className="file-icon">
                          <span>{fileIcon[cell.value]}</span>
                        </td>
                      );

                    case "Category":
                      return (
                        <td key={index}>
                          <p
                            className="category"
                            style={{
                              background: `${
                                cell.row.values.bg_color || "rgb(203, 190, 196)"
                              }`,
                            }}
                          >
                            {cell.value || "Other"}
                          </p>
                        </td>
                      );

                    case "Tax Year":
                      return (
                        <td key={index}>
                          {cell.value !== "" ? cell.value : "N/A"}
                        </td>
                      );

                    case "Case ID":
                      return (
                        <td key={index}>
                          {cell.value !== "" ? cell.value : "N/A"}
                        </td>
                      );

                    case "Modified At":
                      return (
                        <td key={index}>
                          {cell.value !== "0000-00-00" &&
                          cell.value !== "0000-00-00 00:00:00"
                            ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                            : "N/A"}
                        </td>
                      );

                    case "Modified By":
                      return (
                        <td key={index}>
                          {cell.value !== null && cell.value !== ""
                            ? cell.value
                            : "N/A"}
                        </td>
                      );

                    case "Sub Folder":
                      return (
                        <td key={index}>
                          {cell.value !== "" && cell.value !== null
                            ? cell.value
                            : "N/A"}
                        </td>
                      );

                    default:
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AllDocumentsTable;
