import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MdModeEdit } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import EditCategoryForm from "./EditCategoryForm";
import ButtonUI from "../material-ui/ButtonUI";
import editCategory from "../../api/editCategory";
import { AuthContext } from "../../context/Auth/AuthContext";
import { categories } from "../common/Categories";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const editCategoryFormSchema = yup.object().shape({
  description: yup.string().required("Description is required field"),
  badge: yup.string().required("Badge name is required field"),
  bgColor: yup.string(),
});

const EditCategoryPopup = ({ setCategoryUpdatedToTrue, tableValues }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { token, userID } = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editCategoryFormSchema),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onEditCategoryFormSubmit = async (editCategoryFormData) => {
    setLoading(true);

    const editCategoryFormResponse = await editCategory({
      token: token,
      user_id: userID,
      id: tableValues.id,
      name: editCategoryFormData.name,
      description: editCategoryFormData.description,
      badge: editCategoryFormData.badge,
      bgColor: editCategoryFormData.bgColor,
    });

    // categories[editCategoryFormData.name] = categories[tableValues.Category];

    // categories[editCategoryFormData.name] = {
    //   ...editCategoryFormData.name.name,
    //   name: editCategoryFormData.name.substring(
    //     0,
    //     editCategoryFormData.name.indexOf(" ") !== -1
    //       ? editCategoryFormData.name.indexOf(" ")
    //       : 8
    //   ),
    // };

    if (editCategoryFormResponse.code === 200) {
      setCategoryUpdatedToTrue();
      setOpen(false);
      setLoading(false);

      toast.success("Category is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<MdModeEdit />}
        style={{
          backgroundColor: "#17a2b8",
          color: " white",
          marginRight: "5px",
        }}
        onClickFunc={handleClickOpen}
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <MdModeEdit />
          </div>
          <div className="popup-header-title">Edit Category</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <EditCategoryForm
            register={register}
            control={control}
            errors={errors}
            tableValues={tableValues}
          />
        </DialogContent>
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              loading={loading}
              variant="contained"
              buttonText="Submit"
              handleSubmit={handleSubmit}
              onFormSubmit={onEditCategoryFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default EditCategoryPopup;
