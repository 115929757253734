import React, { useContext } from "react";
import { Navigate } from "react-router";
import { AuthContext } from "../../context/Auth/AuthContext";
import SideBar from "../common/Sidebar";

const PrivateRoute = ({ children }) => {
  const { token } = useContext(AuthContext);
  return token ? (
    <div className="dms-pages">
      <div className="sidebar">
        <SideBar />
      </div>
      {children}
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
