import React, { useEffect, useState, useContext } from "react";
import ReactPaginate from "react-paginate";
import TextField from "@mui/material/TextField";
import { AiOutlineSearch } from "react-icons/ai";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Navbar from "../components/common/Navbar";
import StarredTable from "../components/starred-document-table/StarredTable";
import { AuthContext } from "../context/Auth/AuthContext";
import starredDocuments from "../api/starred";
import Loading from "../components/material-ui/Loading";
import baseUrl from "../config/baseUrl";
import "../styles/Starred.css";

const Starred = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [starredDocumentsResponse, setStarredDocumentsResponse] = useState([]);
  const [documentFavouriteState, setDocumentFavouriteState] = useState(0);
  const [globalSearch, setGlobalSearch] = useState("");
  const { token, userID, isAdmin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/apireact/allstarred?limit=20&offset=0&isAdmin=${isAdmin}&user_id=${userID}`
  );

  let limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";

  const fetchStarredDocuments = async () => {
    setLoading(true);
    const response = await starredDocuments({ token, api });
    setStarredDocumentsResponse(response?.data);
    setPageCount(Math.ceil(response?.allStarCount / limit));
    if (currentPage > Math.ceil(response?.allStarCount / limit)) {
      setCurrentPage(Math.ceil(response?.allStarCount / limit));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStarredDocuments();
  }, [api, documentFavouriteState]);

  const removeDocumentsFromFavouriteList = (documentList) => {
    setDocumentFavouriteState((count) => count + 1);
  };

  useEffect(() => {
    setApi(
      `${baseUrl}/apireact/allstarred?limit=${limit}&offset=${offset}${global_search}&isAdmin=${isAdmin}&user_id=${userID}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/apireact/allstarred?limit=${20}&offset=${0}${global_search}&isAdmin=${isAdmin}&user_id=${userID}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="starred-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="starred-content">
        <div className="starred-table-header">
          <TextField
            name="globalSearch"
            label="Search"
            type="text"
            autoComplete="off"
            value={globalSearch}
            onChange={onGlobalSearchChange}
            variant="outlined"
            InputProps={{
              endAdornment: <AiOutlineSearch />,
            }}
          />
        </div>
        {!loading ? (
          starredDocumentsResponse.length > 0 ? (
            <div className="starred-table-content">
              <StarredTable
                loading={loading}
                removeDocumentsFromFavouriteList={
                  removeDocumentsFromFavouriteList
                }
                starredDocumentsResponse={starredDocumentsResponse}
              />
              <div className="starred-table-pagination">
                <ReactPaginate
                  previousLabel={<MdOutlineArrowBackIos />}
                  nextLabel={<MdOutlineArrowForwardIos />}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"starred-pagination"}
                  previousLinkClassName={"starred-pagination-link"}
                  nextLinkClassName={"starred-pagination-link"}
                  disabledClassName={"starred-pagination-link-disabled"}
                  activeClassName={"starred-pagination-link-active"}
                />
              </div>
            </div>
          ) : (
            <h5 className="no-starred-data">No Data Available</h5>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Starred;
