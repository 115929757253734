import baseUrl from "../config/baseUrl";

const uploadDocument = async ({
  token,
  case_number,
  document_title,
  tax_year,
  category,
  user_id,
  file,
}) => {
  const formData = new FormData();
  formData.append("case_number", case_number);
  formData.append("document_title", document_title);
  formData.append("tax_year", tax_year);
  formData.append("category", category);
  formData.append("user_id", user_id);
  file.forEach((file) => {
    formData.append("document_name[]", file);
  });

  try {
    const response = await fetch(
      `${baseUrl}/caselist/upload_multiple_documents`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default uploadDocument;
