import baseUrl from "../config/baseUrl";

const addCategory = async ({
  token,
  user_id,
  name,
  description,
  badge,
  bgColor,
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("name", name);
  formData.append("description", description);
  formData.append("badge", badge);
  if (bgColor === "#000000") {
    formData.append("bg_color", "rgb(203, 190, 196)");
  } else {
    formData.append("bg_color", bgColor);
  }
  try {
    const response = await fetch(`${baseUrl}/apireact/add_category`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addCategory;
