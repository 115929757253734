import React, { useContext, useEffect, useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { CgFileDocument } from "react-icons/cg";
import { FaUserCog } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import notificationData from "../../api/notification";
import { AuthContext } from "../../context/Auth/AuthContext";
import "../../styles/NotificationDropdown.css";
import Loading from "../material-ui/Loading";

const NotificationDropdown = ({ closeNotificationBox }) => {
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState([]);
  const { token, userID, isAdmin } = useContext(AuthContext);

  const getNotificationData = async () => {
    setLoading(true);
    const data = await notificationData({ token, userID, isAdmin });
    const filteredData = data?.notificationContent.filter(
      (data) => data !== null
    );
    setShowNotification(filteredData);
    setLoading(false);
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    <div className="notification-content">
      <div className="notification-header">
        <div className="notification-heading">NOTIFICATION</div>
        <div className="close-notification" onClick={closeNotificationBox}>
          <RiCloseCircleFill />
        </div>
      </div>
      {!loading ? (
        showNotification.length !== 0 ? (
          showNotification?.map((notification, index) => (
            <div className="notification-with-icon" key={index}>
              <div
                style={{
                  color:
                    notification.type === "document"
                      ? "#007bff"
                      : notification.type === "user"
                      ? "#10e232"
                      : "#d50c2acc",
                }}
              >
                {notification.type === "document" ? (
                  <CgFileDocument />
                ) : notification.type === "user" ? (
                  <FaUserCog />
                ) : (
                  <BiCategory />
                )}
              </div>
              <div className="notifications">
                <span style={{ fontWeight: "500" }}>{notification.name}</span>{" "}
                <span>{notification.type}</span> has been{" "}
                <span style={{ fontWeight: "500" }}>{notification.action}</span>{" "}
                on <span>{notification.date.substring(0, 10)}</span>
              </div>
            </div>
          ))
        ) : (
          <div className="no-notifications">No pending notification</div>
        )
      ) : (
        <div className="loading-notification">Loading ...</div>
      )}
    </div>
  );
};

export default NotificationDropdown;
