import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  token: JSON.parse(localStorage.getItem("token")) || null,
  userID: JSON.parse(localStorage.getItem("userID")) || null,
  userName: JSON.parse(localStorage.getItem("userName")) || null,
  isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(state.token));
    localStorage.setItem("userID", JSON.stringify(state.userID));
    localStorage.setItem("userName", JSON.stringify(state.userName));
    localStorage.setItem("isAdmin", JSON.stringify(state.isAdmin));
  }, [state.token]);

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        userID: state.userID,
        userName: state.userName,
        isAdmin: state.isAdmin,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
