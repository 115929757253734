import React, { useContext, useMemo } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import RestoreCategory from "./RestoreCategory";
import PermanentlyDeleteCategory from "./PermanentlyDeleteCategory";
import { Columns } from "./Column";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import Loading from "../../material-ui/Loading";
import "../../../styles/Table.css";
import { AuthContext } from "../../../context/Auth/AuthContext";

const Table = ({
  loading,
  setCategoryRestoredToTrue,
  setCategoryDeletedToTrue,
  trashedCategory,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => trashedCategory, [trashedCategory]);

  const { isAdmin } = useContext(AuthContext);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      ...(isAdmin !== "2"
        ? [
            {
              Header: "Action",
              Cell: ({ row }) => (
                <>
                  <RestoreCategory
                    id={row.values.id}
                    setCategoryRestoredToTrue={setCategoryRestoredToTrue}
                  />
                  <PermanentlyDeleteCategory
                    id={row.values.id}
                    setCategoryDeletedToTrue={setCategoryDeletedToTrue}
                  />
                </>
              ),
            },
          ]
        : []),
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: ["id", "bg_color"],
      },
    },
    tableHooks,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map(
    (row) => `'${row.values.id}'`
  );
  const documentTableDataID = trashedCategory.map((row) => "tableData");

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === documentTableDataID.length;

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setCategoryRestoredToTrue={setCategoryRestoredToTrue}
          setCategoryDeletedToTrue={setCategoryDeletedToTrue}
        />
      </div>
    );
  };

  return (
    <div className="trash-doc-table-content table-content">
      {!loading ? (
        <table
          className="documents-table deleted-category-tables"
          {...getTableProps()}
        >
          <thead className="documents-table-header">
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
                className="documents-table-header-row"
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      key={index}
                      className="documents-table-header-data"
                      {...column.getHeaderProps()}
                    >
                      {column.id == "selection" ? (
                        <SelectionHeader
                          getToggleAllRowsSelectedProps={
                            getToggleAllRowsSelectedProps
                          }
                        />
                      ) : (
                        column.render("Header")
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="documents-table-body" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    switch (cell.column.Header) {
                      case "Badge":
                        return (
                          <td key={index}>
                            <p
                              className="category"
                              style={{
                                background: `${
                                  cell.row.values.bg_color ||
                                  "rgb(203, 190, 196)"
                                }`,
                              }}
                            >
                              {cell.row.values.badge || "Other"}
                            </p>
                          </td>
                        );

                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;
