export const Columns = [
  {
    Header: "ID",
    accessor: "documentID",
  },
  {
    Header: "Case ID",
    accessor: "case_number",
  },
  {
    Header: "Name",
    accessor: "uncategorized_from_name",
  },
  {
    Header: "Tax Year",
    accessor: "tax_year",
  },
  {
    Header: "File",
    accessor: "extension",
  },
  {
    Header: "File Name",
    accessor: "title",
  },
  {
    Header: "Document Description",
    accessor: "document_desc",
  },
  {
    Header: "Category Name",
    accessor: "category",
  },
  {
    Header: "Category",
    accessor: "badge",
  },
  {
    Header: "Email",
    accessor: "uncategorized_email",
  },
  {
    Header: "Category Color",
    accessor: "bg_color",
  },
  {
    Header: "Sub Folder",
    accessor: "subfolder_name",
  },
  {
    Header: "Modified By",
    accessor: "modified_user_id",
  },
  {
    Header: "Modified At",
    accessor: "date_modified",
  },
];
