export const AllCasesColumns = [
  {
    Header: "ID",
    accessor: "documentID",
  },
  {
    Header: "Case ID",
    accessor: "case_number",
  },
  {
    Header: "Name",
    accessor: "NAME",
  },
  {
    Header: "Uncategorized Name",
    accessor: "uncategorized_from_name",
  },
  {
    Header: "Folder",
    accessor: "document_title",
  },
  {
    Header: "Tax Year",
    accessor: "tax_year",
  },
  {
    Header: "File",
    accessor: "extension",
  },
  {
    Header: "File Name",
    accessor: "title",
  },
  {
    Header: "Assign User",
    accessor: "user_name",
  },
  {
    Header: "User ID",
    accessor: "user_id",
  },
  {
    Header: "Document Description",
    accessor: "document_desc",
  },
  {
    Header: "Source",
    accessor: "uncategorized_flag",
  },
  {
    Header: "Category Name",
    accessor: "category",
  },
  {
    Header: "Category",
    accessor: "badge",
  },
  {
    Header: "Sub Folder",
    accessor: "subfolder_name",
  },
  {
    Header: "Email",
    accessor: "uncategorized_email",
  },
  {
    Header: "Document Count",
    accessor: "email_doc_count",
  },

  {
    Header: "Category Color",
    accessor: "bg_color",
  },
  {
    Header: "Modified By",
    accessor: "modified_user_id",
  },
  {
    Header: "Uploaded",
    accessor: "date_entered",
  },
];
