import React, { useContext } from "react";
import { MdContentCopy } from "react-icons/md";
import { toast } from "react-toastify";
import copyUrl from "../../../api/copyUrl";
import { AuthContext } from "../../../context/Auth/AuthContext";
import ButtonUI from "../../material-ui/ButtonUI";

const CopyLinkPopup = ({ caseNo, tableValues }) => {
  const { token } = useContext(AuthContext);

  const copyLink = async () => {
    const response = await copyUrl(token, tableValues.documentID);
    const data = await response.data;
    const link = data.document_url;
    navigator.clipboard.writeText(link);
    toast.success("Link copied successfully", {
      position: "top-center",
      autoClose: 3000,
      theme: "dark",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <ButtonUI
      formButton={false}
      variant="outlined"
      buttonText={<MdContentCopy />}
      style={{
        backgroundColor: "#28a745",
        // boxShadow: "0 0 0.4rem #2a9ceb57, inset 0 0 0 2px #2a9ceb57",
        color: "white",
        marginRight: "5px",
      }}
      onClickFunc={copyLink}
    />
  );
};

export default CopyLinkPopup;
