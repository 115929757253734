import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { RiFilter2Fill, RiCloseCircleFill } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import { AuthContext } from "../../../context/Auth/AuthContext";
import caseListData from "../../../api/caselist";
import editDocumentDetails from "../../../api/editDocumentDetails";
import Table from "./Table";
import pdfDetails from "../../../api/pdfDetails";
import PdfEditor from "../../pdf-viewer/PdfEditor";
import Loading from "../../material-ui/Loading";
import BasicDatePicker from "../../material-ui/DatePicker";
import ButtonUI from "../../material-ui/ButtonUI";
import "../../../styles/DocumentTable.css";

const CaseListTable = ({
  addDocumentTab,
  apiLink,
  admin,
  caseList,
  categoryName,
  assignUserFilter,
  folderName,
  assignPage,
  page,
}) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [docsCount, setDocsCount] = useState(0);

  const [openFilter, setOpenFilter] = useState(false);
  const [userList, setUserList] = useState([]);
  const [documentUpdated, setDocumentUpdated] = useState(0);
  const [documentDeleted, setDocumentDeleted] = useState(0);
  const [documentViewer, setDocumentViewer] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const [pdfBase64, setPdfBase64] = useState("");
  const [pdfEditor, setPdfEditor] = useState(false);
  const [docFileType, setDocFileType] = useState("");
  const [pdfViewerTitle, setPdfViewerTitle] = useState("");

  const [docCaseID, setDocCaseID] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");

  const [documentResponse, setDocumentResponse] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);

  const [globalSearch, setGlobalSearch] = useState("");
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedFaxFolder, setSelectedFaxFolder] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAssignUser, setSelectedAssignUser] = useState(null);
  const [searchedUserID, setSearchedUserID] = useState("");
  const [searchedDate, setSearchedDate] = useState(null);

  const { token, userID, isAdmin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${apiLink}?limit=20&offset=0&isAdmin=${admin}&user_id=${userID}`
  );

  const limit = 20;
  const offset = currentPage * limit;

  let searchValue = globalSearch ? `&global_search=${globalSearch}` : "";
  let source = selectedSource ? `&source=${selectedSource.id}` : "";
  let categoryFilter = selectedCategory ? `&category=${selectedCategory}` : "";
  let assignUser = selectedAssignUser
    ? `&user_id_filter=${selectedAssignUser.id}`
    : "";
  let subfolder_name = selectedFaxFolder
    ? `&subfolder_name=${selectedFaxFolder}`
    : "";
  let date_modified = searchedDate
    ? `&date_modified=${searchedDate.toLocaleDateString("fr-CA")}`
    : "";
  let case_number = searchedUserID ? `&case_number=${searchedUserID}` : "";

  const fileTypeOptions = [
    {
      id: "E33333",
      name: "Email",
    },
    {
      id: "F22222",
      name: "Fax",
    },
    {
      id: "S11111",
      name: "Scan",
    },
    {
      id: "D44444",
      name: "Service Docs",
    },
  ];

  const faxFolderOptions = [
    "Accounting",
    "AR Fax Box",
    "Bookkeeper Fax",
    "CCS Fax",
    "Central Team Faxes",
    "CTR AS Fax Box",
    "Current Year Tax Prep",
    "HR Fax Box",
    "Investigation Transcript Fax Box",
    "IRS Fax Box",
    "IRS Transcripts Box",
    "North Team Faxes",
    "PR Team Faxes",
    "Resolution Faxes",
    "Resolution Faxes",
    "Resolution Outbound",
    "Sales Fax Line",
    "Sales Outbound",
    "South Team Faxes",
    "Test Email",
    "Test Fax",
    "West Team Faxes",
  ];

  const setDocumentUpdatedToTrue = () => {
    setDocumentUpdated((count) => count + 1);
  };

  const setDocumentDeletedToTrue = () => {
    setDocumentDeleted((count) => count + 1);
  };

  const changeActiveTab = () => {
    setDocumentViewer(false);
    setPdfEditor(false);
  };

  const changeDocumentViewerState = async (
    caseID,
    name,
    category,
    desc,
    id,
    type
  ) => {
    setLoading(true);
    const response = await pdfDetails(token, id);
    const data = await response.data;
    if (caseList === "all") {
      setDocCaseID(caseID);
    }
    setName(name);
    setCategory(category);
    setDescription(desc);
    setDocumentId(id);
    setPdfLink(data.document_url);
    setDocFileType(type);
    setPdfBase64(data.pdfBlobData);
    setPdfViewerTitle(name);
    setDocumentViewer(true);
    setLoading(false);
  };

  const documentResponseFunc = async () => {
    setLoading(true);
    const response = await caseListData({ token, api });
    setPageCount(Math.ceil(response?.docsCount / 20));
    setDocsCount(response?.all_document_email_count);
    setUserList(response?.userList);
    setCategoryOption(response?.category);

    setDocumentResponse(
      response?.data?.length !== 0 || response?.data !== null
        ? response?.data
        : []
    );
    setLoading(false);
  };

  useEffect(() => {
    setApi(
      `${apiLink}?limit=${limit}&offset=${offset}&isAdmin=${admin}&user_id=${userID}${searchValue}${source}${subfolder_name}${date_modified}${case_number}${categoryFilter}${assignUser}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${apiLink}?limit=20&offset=0&isAdmin=${admin}&user_id=${userID}${searchValue}${source}${subfolder_name}${date_modified}${case_number}${categoryFilter}${assignUser}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  useEffect(() => {
    documentResponseFunc();
  }, [api, documentUpdated, documentDeleted]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const onSourceOptionChange = (selectedSource) => {
    setSelectedSource(selectedSource);
  };

  const onCategoryOptionChange = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  const onFaxFolderChange = (selectedFaxFolder) => {
    setSelectedFaxFolder(selectedFaxFolder);
  };

  const onAssignUserOptionChange = (selectedAssignUser) => {
    setSelectedAssignUser(selectedAssignUser);
  };

  const onSearchedUserIDChange = (e) => {
    setSearchedUserID(e.target.value);
  };

  const onSearchedDateChange = (newDate) => {
    setSearchedDate(newDate);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const filtervisibility = () => {
    setOpenFilter(!openFilter);
  };

  const applyFilters = () => {
    setCurrentPage(0);
    setApi(
      `${apiLink}?limit=20&offset=0&isAdmin=${admin}&user_id=${userID}${searchValue}${source}${subfolder_name}${date_modified}${case_number}${categoryFilter}${assignUser}`
    );
  };

  const clearFilters = () => {
    setGlobalSearch("");
    setSelectedCategory(null);
    setSelectedSource(null);
    setSelectedAssignUser(null);
    setSearchedUserID("");
    setSearchedDate(null);
    setCurrentPage(0);
    setApi(`${apiLink}?limit=20&offset=0&isAdmin=${admin}&user_id=${userID}`);
  };

  const onEditDocumentFormSubmit = async (editDocumentFormData) => {
    const editDocumentFormResponse = await editDocumentDetails({
      token: token,
      user_id: userID,
      case_number: docCaseID,
      document_id: documentId,
      document_desc: description,
      document_title: name,
      category: category,
    });

    if (editDocumentFormResponse.code === 200) {
      setDocumentViewer(false);
      setDocumentUpdatedToTrue();
      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="document-table">
      {!documentViewer ? (
        <div className="documents-content">
          <div className="table-header">
            <TextField
              name="globalSearch"
              label="Search"
              type="text"
              autoComplete="off"
              value={globalSearch}
              onChange={onGlobalSearchChange}
              variant="outlined"
              InputProps={{
                endAdornment: <AiOutlineSearch />,
              }}
            />
            {caseList === "all" ? (
              <div className="doc-header-btn">
                <button className="filter-documents" onClick={filtervisibility}>
                  <RiFilter2Fill />
                </button>
              </div>
            ) : null}
          </div>
          <div
            className={openFilter ? "document-filter" : "hide-document-filter"}
          >
            {assignUserFilter === true ? (
              <Autocomplete
                id="select-file-option"
                options={userList.map((user, id) => {
                  return {
                    ...user,
                    label: `${user.first_name} ${user.last_name}`,
                  };
                })}
                sx={{ width: 154 }}
                renderInput={(params) => (
                  <TextField {...params} label="Assign User" />
                )}
                value={selectedAssignUser}
                onChange={(e, selectedOption) =>
                  onAssignUserOptionChange(selectedOption)
                }
              />
            ) : null}
            <Autocomplete
              id="select-file-option"
              options={fileTypeOptions.map((source, id) => {
                return {
                  ...source,
                  label: source.name,
                };
              })}
              sx={{ width: 154 }}
              renderInput={(params) => <TextField {...params} label="Source" />}
              value={selectedSource}
              onChange={(e, selectedOption) =>
                onSourceOptionChange(selectedOption)
              }
            />
            {page === "processed" ? (
              <Autocomplete
                id="select-file-option"
                options={categoryOption}
                sx={{ width: 154 }}
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
                value={selectedCategory}
                onChange={(e, selectedOption) =>
                  onCategoryOptionChange(selectedOption)
                }
              />
            ) : null}
            {page === "processed" ? null : (
              <Autocomplete
                id="select-file-option"
                options={faxFolderOptions}
                sx={{ width: 154 }}
                renderInput={(params) => (
                  <TextField {...params} label="Fax Folder" />
                )}
                value={selectedFaxFolder}
                onChange={(e, selectedOption) =>
                  onFaxFolderChange(selectedOption)
                }
              />
            )}
            <TextField
              name="caseID"
              label="Case ID"
              type="text"
              autoComplete="off"
              value={searchedUserID}
              onChange={onSearchedUserIDChange}
              variant="outlined"
            />
            <BasicDatePicker
              value={searchedDate}
              setValue={onSearchedDateChange}
              label="Date Modified"
            />
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Apply"
              style={{
                width: "80px",
                height: "35px",
                color: "#007bff",
                backgroundColor: "white",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
              }}
              onClickFunc={() => {
                applyFilters();
              }}
            ></ButtonUI>
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Reset"
              style={{
                width: "80px",
                height: "35px",
                color: "#007bff",
                backgroundColor: "white",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
              }}
              onClickFunc={() => {
                clearFilters();
              }}
            ></ButtonUI>
          </div>
          {!loading ? (
            documentResponse?.length !== 0 ? (
              <div className="document__table">
                <Table
                  loading={loading}
                  openFilter={openFilter}
                  documentResponse={documentResponse}
                  categoryOption={categoryOption}
                  addDocumentTab={addDocumentTab}
                  setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                  setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                  changeDocumentViewerState={changeDocumentViewerState}
                  caseList={caseList}
                  userList={userList}
                  categoryName={categoryName}
                  folderName={folderName}
                  assignPage={assignPage}
                />
                <div className="case-table-pagination">
                  <div className="docs-count">{`${docsCount} Documents`}</div>
                  <ReactPaginate
                    previousLabel={<MdOutlineArrowBackIos />}
                    nextLabel={<MdOutlineArrowForwardIos />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"doc-pagination"}
                    previousLinkClassName={"doc-pagination-link"}
                    nextLinkClassName={"doc-pagination-link"}
                    disabledClassName={"doc-pagination-link-disabled"}
                    activeClassName={"doc-pagination-link-active"}
                  />
                </div>
              </div>
            ) : (
              <h5 className="no-doc-data">No Data Available</h5>
            )
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <>
          {!loading ? (
            !pdfEditor ? (
              <div className="all-document-pdf-viewer">
                <div className="all-document-pdf-viewer-header">
                  <div className="all-document-pdf-viewer-title">
                    {pdfViewerTitle}
                  </div>
                  <div className="all-document-pdf-viewer-icons">
                    {docFileType === "pdf" && isAdmin !== "2" && (
                      <div
                        className="all-document-pdf-viewer-icon"
                        onClick={() => setPdfEditor(true)}
                      >
                        <TbEdit />
                      </div>
                    )}
                    <div
                      className="all-document-pdf-viewer-icon"
                      onClick={() => setDocumentViewer(false)}
                    >
                      <RiCloseCircleFill />
                    </div>
                  </div>
                </div>
                <div className="document-viewer-with-from">
                  <div className="iframe-wrapper">
                    <iframe
                      title={pdfViewerTitle}
                      src={pdfLink}
                      width="100%"
                      height="590"
                    ></iframe>
                  </div>
                  <div className="document-viewer-form">
                    <TextField
                      name="caseId"
                      label="Case ID"
                      type="text"
                      autoComplete="off"
                      value={docCaseID}
                      onChange={(e) => setDocCaseID(e.target.value)}
                      variant="outlined"
                      required={category !== "Client Unknown" ? true : false}
                      fullWidth
                    />
                    <TextField
                      name="name"
                      label="Name"
                      type="text"
                      autoComplete="off"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      style={{ marginTop: "15px" }}
                      variant="outlined"
                      required
                      fullWidth
                    />
                    <FormControl fullWidth required>
                      <InputLabel
                        id="select-label"
                        style={{ marginTop: "15px" }}
                      >
                        Category
                      </InputLabel>
                      <Select
                        name="category"
                        sx={{ marginTop: "15px" }}
                        labelId="select-label"
                        variant="outlined"
                        id="simple-select"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        label="category"
                      >
                        {categoryOption.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      name="description"
                      label="Description"
                      multiline
                      minRows={3}
                      maxRows={3}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      variant="outlined"
                      style={{ marginTop: "15px" }}
                      fullWidth
                    />
                    <ButtonUI
                      formButton={false}
                      loading={loading}
                      variant="contained"
                      buttonText="Submit"
                      style={{
                        width: "150px",
                        height: "35px",
                        boxShadow: "none",
                        color: "#fff",
                        fontWeight: "500",
                        borderRadius: "4px",
                        letterSpacing: "0.07rem",
                        marginTop: "15px",
                      }}
                      disabled={
                        isAdmin === "2" ||
                        (category !== "Client Unknown"
                          ? docCaseID === "" || name === "" || category === ""
                          : name === "" || category === "")
                      }
                      onClickFunc={onEditDocumentFormSubmit}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="all-document-pdf-editor">
                <div
                  onClick={() => setPdfEditor(false)}
                  className="all-document-pdf-editor-icon"
                  title="Back"
                >
                  <IoArrowBackCircleSharp />
                </div>
                <div>
                  <PdfEditor
                    pdfBase64={pdfBase64}
                    documentID={documentId}
                    documentTitle={pdfViewerTitle}
                    caseNumber={docCaseID}
                    changeActiveTab={changeActiveTab}
                  />
                </div>
              </div>
            )
          ) : (
            <Loading />
          )}
        </>
      )}
    </div>
  );
};

export default CaseListTable;
