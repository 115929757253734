import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, ChartDataLabels, Tooltip, Legend);

const PieChart = ({ allAssignedUserResponse }) => {
  const dataValue = allAssignedUserResponse.dataset;
  console.log(dataValue);
  const totalDocuments = dataValue.reduce((data, acc) => acc + data, 0);

  const data = {
    labels: allAssignedUserResponse.label,
    datasets: [
      {
        label: "# of Documents",
        data: dataValue,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(40, 221, 110, 0.5)",
          "rgba(186, 20, 237, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(25, 14, 236, 0.5)",
          "rgba(246, 137, 4, 0.5)",
          "rgba(34, 237, 20, 0.5)",
          "rgba(241, 10, 10, 0.5)",
          "rgba(14, 231, 169, 0.5)",
        ],
        borderColor: ["#fff", "#fff", "#fff", "#fff", "#fff"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Pie
      data={data}
      height="400px"
      width="400px"
      options={{
        maintainAspectRatio: false,
        plugins: {
          ChartDataLabels,
          datalabels: {
            font: {
              size: 16,
            },
            display: function (context) {
              let index = context.dataIndex;
              let value = (
                (context.dataset.data[index] * 100) /
                totalDocuments
              ).toFixed(1);
              return value > 0;
            },
            formatter: function (value) {
              return "";
            },
          },
          tooltip: {
            titleFont: {
              size: 14,
            },
            bodyFont: {
              size: 14,
            },
            callbacks: {
              title: function (context) {
                return context[0].label;
              },
              label: function (context) {
                let index = context.dataIndex;
                return `${context.formattedValue}`;
              },
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              padding: 20,
            },
          },
          title: {
            display: true,
            text: "User Assigned Cases",
            padding: {
              top: 10,
              bottom: 30,
            },
            font: { size: 18 },
            color: "#007bff",
          },
        },
      }}
    />
  );
};

export default PieChart;
