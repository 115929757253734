import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function BarChart({ processedUserResponse }) {
  const labels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const randomColor = (number) => {
    return `rgb(${Math.floor(Math.random() * (255 - 160 + 1) + 160)}, 
    ${Math.floor(Math.random() * (180 - 100 + 1) + 100)},
     ${Math.floor(Math.random() * (60 - 10 + 1) + 10)}, 1)`;
  };

  const documentUploadedResponseArray = Object.keys(processedUserResponse).map(
    (key) => ({
      key,
      value: processedUserResponse[key],
    })
  );

  const dataArray = documentUploadedResponseArray.map((docs) => {
    return {
      label: docs.key !== "" ? docs.key : "Manual",
      data: docs.value.map((val) => val.totalUpload),
      backgroundColor: randomColor(
        Math.floor(Math.random() * Math.random() * 10)
      ),
    };
  });

  const data = {
    labels,
    datasets: dataArray,
  };

  return (
    <Bar
      options={{
        maintainAspectRatio: false,
        plugins: {
          ChartDataLabels,
          datalabels: {
            font: {
              size: 0,
            },
            display: function (context) {
              let index = context.dataIndex;
              let value = context.dataset.data[index];
              return value > 0;
            },
          },
          tooltip: {
            titleFont: {
              size: 14,
            },
            bodyFont: {
              size: 14,
            },
          },
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              padding: 20,
            },
          },
          title: {
            display: true,
            text: "User Processed Cases",
            padding: {
              top: 10,
              bottom: 30,
            },
            font: { size: 18 },
            color: "#007bff",
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      }}
      data={data}
    />
  );
}
