import baseUrl from "../config/baseUrl";

const assignUser = async ({
  token,
  assigned_user,
  case_number,
  email,
  category_id,
  categoryName,
  rowCategory,
  folderName,
  assignee,
}) => {
  const formData = new FormData();
  if (category_id === "all" && rowCategory === false) {
    formData.append("case_number", case_number);
  }
  formData.append("assigned_user", assigned_user);
  if (category_id !== "all") {
    formData.append("email", email);
    formData.append("category_id", `'${category_id}'`);
    formData.append("uncategorized_from_name", folderName);
  }
  if (category_id === "all" && rowCategory === true) {
    formData.append("email", email);
    formData.append("category_id", `'${categoryName}'`);
    formData.append("uncategorized_from_name", folderName);
  }
  if (category_id === "all" && categoryName === "Scanner") {
    formData.append("user_id", `'${assignee}'`);
  }

  try {
    const response = await fetch(
      `${baseUrl}/caselist/bulk_assigned_user_in_case_list`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default assignUser;
