import baseUrl from "../config/baseUrl";

const changeUserStatus = async ({ token, user_id, id, status }) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("id", id);
  formData.append("status", status);

  try {
    const response = await fetch(`${baseUrl}/apireact/update_users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default changeUserStatus;
