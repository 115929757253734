import React, { useMemo, useContext, useEffect } from "react";
import { format } from "date-fns";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { AllCasesColumns } from "./AllCasesColumns";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import CopyLinkPopup from "../case-table/CopyLinkPopup";
import DocumentViewer from "../case-table/DocumentViewer";
import Loading from "../../material-ui/Loading";
import DeleteDocumentPopup from "../case-table/DeleteDocumentPopup";
import EditDocumentPopup from "../case-table/EditDocumentPopup";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/Table.css";
import { fileIcon } from "../../common/FileIcons";

const Table = ({
  loading,
  openFilter,
  documentResponse,
  categoryOption,
  addDocumentTab,
  setDocumentUpdatedToTrue,
  setDocumentDeletedToTrue,
  changeDocumentViewerState,
  caseList,
  userList,
  categoryName,
  folderName,
  assignPage,
}) => {
  const columns = useMemo(() => AllCasesColumns, []);
  const data = useMemo(() => documentResponse, [documentResponse]);

  const { isAdmin } = useContext(AuthContext);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => {
          return row?.values?.email_doc_count > 1 ? (
            <span
              style={{
                cursor: "pointer",
                color: "#000fb5",
              }}
              onClick={() => addDocumentTab(row.values)}
            >
              {`${row?.values?.email_doc_count} Documents`}
            </span>
          ) : (
            <>
              {isAdmin === "2" ? (
                <DocumentViewer
                  tableValues={row.values}
                  changeDocumentViewerState={changeDocumentViewerState}
                />
              ) : (
                <>
                  <EditDocumentPopup
                    tableValues={row.values}
                    categoryOption={categoryOption}
                    setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                  />
                  <CopyLinkPopup tableValues={row.values} />
                  <DocumentViewer
                    tableValues={row.values}
                    changeDocumentViewerState={changeDocumentViewerState}
                  />
                  <DeleteDocumentPopup
                    tableValues={row.values}
                    setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                  />
                </>
              )}
            </>
          );
        },
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: [
          "documentID",
          "favourite",
          "document_desc",
          "bg_color",
          "category",
          "name",
          "case_number",
          "uncategorized_email",
          "email_doc_count",
          "document_title",
          "subfolder_name",
          "uncategorized_from_name",
          "user_id",
        ],
      },
    },
    useSortBy,
    tableHooks,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <span
                style={{
                  visibility: isAdmin === "1" ? "visible" : "hidden",
                }}
              >
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </span>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map((row) =>
    caseList === "all"
      ? row.values.category === "Email" ||
        row.values.category === "Fax" ||
        row.values.category === "Scanner" ||
        row.values.category === "serviceDocs" ||
        row.values.category === "Client Unknown"
        ? ""
        : `'${row.values.case_number}'`
      : `''`
  );
  const selectedFlatRowsEmail = selectedFlatRows.map((row) =>
    caseList === "all"
      ? row.values.category === "Email" ||
        row.values.category === "Fax" ||
        row.values.category === "Scanner" ||
        row.values.category === "serviceDocs" ||
        row.values.category === "Client Unknown"
        ? `'${row.values.uncategorized_email}'`
        : ""
      : `'${row.values.uncategorized_email}'`
  );

  const selectedFlatRowsAssignUser = selectedFlatRows.map((row) =>
    caseList === "all"
      ? row.values.category === "Scanner"
        ? row.values.user_id
        : ""
      : ""
  );

  const selectedFlatRowsName = selectedFlatRows.map((row) =>
    caseList === "scanner"
      ? `'${row.values.document_title}'`
      : `'${row.values.uncategorized_from_name}'`
  );

  const selectedFlatRowsCategory = selectedFlatRows.map((row) => {
    if (caseList === "all") {
      if (row.values.category === "Email") {
        return "Email";
      } else if (row.values.category === "Fax") {
        return "Fax";
      } else if (row.values.category === "Scanner") {
        return "Scanner";
      } else if (row.values.category === "serviceDocs") {
        return "serviceDocs";
      } else if (row.values.category === "Client Unknown") {
        return "Client Unknown";
      } else {
        return "Others";
      }
    } else {
      return "Others";
    }
  });

  let rowCategoryName;

  if (selectedFlatRowsCategory.includes("Email")) {
    rowCategoryName = "Email";
  }

  if (selectedFlatRowsCategory.includes("Fax")) {
    rowCategoryName = "Fax";
  }

  if (selectedFlatRowsCategory.includes("Scanner")) {
    rowCategoryName = "Scanner";
  }

  if (selectedFlatRowsCategory.includes("serviceDocs")) {
    rowCategoryName = "serviceDocs";
  }

  if (selectedFlatRowsCategory.includes("Client Unknown")) {
    rowCategoryName = "Client Unknown";
  }

  console.log(rowCategoryName);
  const documentTableDataID = documentResponse.map((row) => "tableData");

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === documentTableDataID.length;

  const SelectionHeader = (row) => {
    console.log(row);
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
          userList={userList}
          email={selectedFlatRowsEmail}
          category_id={caseList}
          categoryName={rowCategoryName}
          rowCategory={selectedFlatRowsID.includes("")}
          folderName={selectedFlatRowsName}
          assignee={selectedFlatRowsAssignUser}
        />
      </div>
    );
  };

  return (
    <div className={!openFilter ? "table-content" : "resized-table-content"}>
      {!loading ? (
        <table
          className="documents-table all-case-list-tables"
          {...getTableProps()}
        >
          <thead className="documents-table-header">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="documents-table-header-row"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="documents-table-header-data"
                      key={index}
                    >
                      <span className="document-table-select-row">
                        {column.id === "selection" ? (
                          <span
                            style={{
                              visibility:
                                isAdmin === "1" ? "visible" : "hidden",
                            }}
                          >
                            <SelectionHeader
                              getToggleAllRowsSelectedProps={
                                getToggleAllRowsSelectedProps
                              }
                            />
                          </span>
                        ) : (
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                          >
                            {column.render("Header")}
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "5px",
                                fontSize: "16px",
                              }}
                            >
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <IoMdArrowDropdown />
                                ) : (
                                  <IoMdArrowDropup />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="documents-table-body" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  className="document-table-body-row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    switch (cell.column.Header) {
                      case "File":
                        return (
                          <td key={index} className="file-icon">
                            <span>{fileIcon[cell.value]}</span>
                          </td>
                        );

                      case "Name":
                        return <td key={index}>{cell.value}</td>;

                      case "File Name":
                        return <td key={index}>{cell.value}</td>;

                      case "Tax Year":
                        return (
                          <td key={index}>
                            {cell.value !== "" ? cell.value : "N/A"}
                          </td>
                        );

                      case "Category":
                        return (
                          <td key={index}>
                            <p
                              className="category"
                              style={{
                                background: `${
                                  cell.row.values.bg_color ||
                                  "rgb(203, 190, 196)"
                                }`,
                              }}
                            >
                              {cell.row.values.badge || "Other"}
                            </p>
                          </td>
                        );

                      case "Source":
                        return (
                          <td key={index}>
                            {cell.value === "E33333"
                              ? "Email"
                              : cell.value === "S11111"
                              ? "Scan"
                              : cell.value === "F22222"
                              ? "Fax"
                              : cell.value === "D44444"
                              ? "Service Docs"
                              : "Others"}
                          </td>
                        );

                      case "Modified By":
                        return (
                          <td key={index}>
                            {cell.value !== null && cell.value !== ""
                              ? cell.value
                              : "N/A"}
                          </td>
                        );

                      case "Assign User":
                        return (
                          <td key={index}>
                            {assignPage
                              ? cell.value
                                ? cell.value
                                : "N/A"
                              : "Unassigned"}
                          </td>
                        );

                      case "Uploaded":
                        return (
                          <td key={index}>
                            {cell.value !== "0000-00-00" &&
                            cell.value !== "0000-00-00 00:00:00"
                              ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                              : "N/A"}
                          </td>
                        );

                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;
