import baseUrl from "../config/baseUrl";

const pdfEditor = async ({
  token,
  case_number,
  document_title,
  document_name,
  document_id,
  user_id,
}) => {
  const formData = new FormData();
  formData.append("case_number", case_number);
  formData.append("user_id", user_id);
  formData.append("document_name", document_name);
  formData.append("document_id", document_id);

  try {
    const response = await fetch(`${baseUrl}/apireact/document_edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default pdfEditor;
