import React from "react";
import { Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import InputUI from "../material-ui/InputUI";

const EditCategoryForm = ({ register, control, errors, tableValues }) => {
  return (
    <Grid className="edit-document">
      <div className="edit-document-form">
        <div className="field">
          <div className="input-field">
            <Controller
              name="name"
              defaultValue={tableValues.name}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputUI
                  name="name"
                  label="Name"
                  type="text"
                  value={value ? value : ""}
                  setValue={onChange}
                  variant="outlined"
                  disabled={true}
                  register={register}
                />
              )}
            />
          </div>
          <span className="error-message">
            {errors["description"]?.message}
          </span>
        </div>
        <div className="field">
          <div className="input-field">
            <Controller
              name="description"
              defaultValue={tableValues.description}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputUI
                  name="description"
                  label="Description"
                  type="text"
                  value={value ? value : ""}
                  setValue={onChange}
                  variant="outlined"
                  register={register}
                />
              )}
            />
          </div>
          <span className="error-message">
            {errors["description"]?.message}
          </span>
        </div>
        <div className="category-with-color">
          <div className="field">
            <Controller
              name="badge"
              defaultValue={tableValues.badge}
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputUI
                  name="badge"
                  label="Badge"
                  type="text"
                  value={value ? value : ""}
                  setValue={onChange}
                  variant="outlined"
                  register={register}
                />
              )}
            />
            <span className="error-message">{errors["badge"]?.message}</span>
          </div>
          <Controller
            name="bgColor"
            defaultValue={tableValues.bg_color}
            control={control}
            render={({ field: { onChange, value } }) => (
              <input
                {...register("bgColor")}
                name="bgColor"
                type="color"
                className="edit-category-bg-color"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </div>
      </div>
    </Grid>
  );
};

export default EditCategoryForm;
