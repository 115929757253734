import React, { useEffect, useState, useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../authConfig";
import Loading from "../components/material-ui/Loading";
import { callMsGraph } from "../graph";
import { AuthContext } from "../context/Auth/AuthContext";
import loginUser from "../api/login";

const Callback = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const { dispatch } = useContext(AuthContext);
  const redirect = useNavigate();

  const handleLogout = async () => {
    instance
      .logoutPopup()
      .then(() => {
        dispatch({ type: "LOGOUT" });
        redirect("/login");
        toast.success("Successfully Logged Out", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const loginUserFn = async (email, id) => {
    const loginData = await loginUser({ email });
    if (loginData?.token) {
      dispatch({
        type: "LOGIN",
        payload: {
          token: loginData.token,
          userID: loginData.result[0].id,
          userName: loginData.result[0].user_email,
          isAdmin: loginData.result[0].is_admin,
        },
      });
      toast.success("Successfully Logged In", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      redirect("/dashboard");
    }
    if (loginData?.code === 401) {
      handleLogout();
    }
  };

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        callMsGraph(response.accessToken).then((response) => {
          setGraphData(response);
          loginUserFn(response.mail, response.id);
        });
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          callMsGraph(response.accessToken).then((response) => {
            setGraphData(response);
          });
        });
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        minWidth: "100vw",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Loading />
    </div>
  );
};

export default Callback;
