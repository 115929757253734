import React, { useMemo } from "react";
import { format } from "date-fns";
import { useTable } from "react-table";
import { Columns } from "./Columns";
import { fileIcon } from "../common/FileIcons";
import { categories } from "../common/Categories";
import Loading from "../material-ui/Loading";
import "../../styles/Table.css";

const RecentsTable = ({ loading, getRecentDocuments }) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => getRecentDocuments, [getRecentDocuments]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: ["id", "bg_color"],
      },
    });

  return (
    <div className="table-content">
      {!loading ? (
        <table {...getTableProps()} className="documents-table recent-tables">
          <thead className="documents-table-header other-tables-header">
            {headerGroups.map((headerGroup, index) => {
              return (
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                  className="documents-table-header-row"
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      {...column.getHeaderProps()}
                      className="documents-table-header-data"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="documents-table-body other-tables-body"
          >
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  {...row.getRowProps()}
                  className="document-table-body-row"
                >
                  {row.cells.map((cell, index) => {
                    switch (cell.column.Header) {
                      case "File":
                        return (
                          <td key={index} className="file-icon">
                            {fileIcon[cell.value]}
                          </td>
                        );
                      case "Case ID":
                        return (
                          <td key={index}>
                            {cell.value !== "" ? cell.value : "N/A"}
                          </td>
                        );
                      case "Category":
                        return (
                          <td key={index}>
                            <p
                              className="category"
                              style={{
                                background: `${
                                  cell.row.values.bg_color ||
                                  "rgb(203, 190, 196)"
                                }`,
                              }}
                            >
                              {cell.row.values.badge || "Other"}
                            </p>
                          </td>
                        );

                      case "Uploaded":
                        return (
                          <td key={index}>
                            {cell.value !== "0000-00-00" &&
                            cell.value !== "0000-00-00 00:00:00"
                              ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                              : "N/A"}
                          </td>
                        );

                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default RecentsTable;
