import React, { useState, useContext } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { AuthContext } from "../../../context/Auth/AuthContext";
import AssignUserPopup from "./AssignUserPopup";

const Dropdown = ({
  selectedRows,
  isEntireTableRowsSelected,
  setDocumentUpdatedToTrue,
  userList,
  email,
  category_id,
  categoryName,
  rowCategory,
  folderName,
  assignee,
}) => {
  const [open, setOpen] = useState(false);
  const [docId, setDocId] = useState();

  const { isAdmin } = useContext(AuthContext);

  const changeDropdownVisibilityFunc = () => {
    setOpen(!open);
  };

  const handleDeleteRows = (selectedRows) => {
    console.log(selectedRows);
    setDocId(selectedRows);
  };

  return (
    <>
      {isAdmin === "0" ? null : (
        <div className="dropdown-button">
          <IoMdArrowDropdown
            onClick={changeDropdownVisibilityFunc}
            style={{
              visibility: selectedRows.length > 0 ? "visible" : "hidden",
            }}
          />

          <div
            className="doc-elements"
            style={{
              display: open ? "block" : "none",
              zIndex: 10,
              marginTop: "3px",
            }}
          >
            <ul className="docs-dropdown-list">
              <li
                className="docs-dropdown-item"
                onClick={() => handleDeleteRows(selectedRows)}
              >
                <AssignUserPopup
                  userList={userList}
                  isEntireTableRowsSelected={isEntireTableRowsSelected}
                  changeDropdownVisibilityFunc={changeDropdownVisibilityFunc}
                  setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                  docId={docId}
                  email={email}
                  category_id={category_id}
                  categoryName={categoryName}
                  rowCategory={rowCategory}
                  folderName={folderName}
                  assignee={assignee}
                />
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Dropdown;
